import React
//  { useState, useEffect } 
 from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
// import GithubProj from "../../components/githubProj/GithubProj";
import { ourProductsLive } from "../../portfolio";
import SpecialityCard from "../../components/specialityCard/SpecialityCard";
import { Fade } from "react-reveal";
import { productsHeader, projects } from "../../portfolio.js";
import FloatButton from '../../components/floatingButton/FloatButton'
import "./Products.css";
import ProjectsImg from "./ProjectsImg";
// import { style } from "glamor";
// import axios from "axios";

// const baseURL = "https://api.github.com/orgs/ainzics/repos";

function Products(props) {
  // const [repos, setRepos] = useState([]);
  const theme = props.theme;

  // const styles = style({
  //   backgroundColor: `${theme.accentBright}`,
  //   ":hover": {
  //     boxShadow: `0 5px 15px ${theme.accentBright}`,
  //   },
  // });

  // useEffect(() => {
  //   axios.get(baseURL).then((response) => {
  //     setRepos(response.data);
  //   });
  // }, []);

  return (
    <div className="projects-main">
      <Header theme={theme} setTheme={props.setTheme} />
      <div className="basic-projects">
        <Fade bottom duration={2000} distance="40px">
          <div className="projects-heading-div">
            <div className="projects-heading-img-div">
              <ProjectsImg theme={theme} />
            </div>
            <div className="projects-heading-text-div">
              <h1
                className="projects-heading-text"
                style={{ color: theme.secondaryText }}
              >
                {productsHeader.title}
              </h1>
              <p
                className="projects-header-detail-text subTitle"
                style={{ color: theme.text }}
              >
                {productsHeader["description"]}
              </p>
            </div>
          </div>
        </Fade>
      </div>
      <Fade bottom duration={2000} distance="20px">
        <h2 className="certs-header" style={{ color: theme.text }}>
          Upcoming Products
        </h2>
      </Fade>
      <div className="repo-cards-div-main">
        {projects.data.map((repo, index) => {
          return <ProjectCard repo={repo} key={index} theme={theme} />;
        })}
      </div>
      <Fade bottom duration={2000} distance="20px">
        <h2 className="certs-header" style={{ color: theme.text }}>
          Our Live Products
        </h2>
      </Fade>
      <Fade bottom duration={2000} distance="20px">
        <div className="main" id="certs">
          <div className="special-body-div">
            {ourProductsLive.ourLiveProducts.map((prof) => {
              return (
                <SpecialityCard specs={prof} key={prof.id} theme={theme} />
              );
            })}
          </div>
        </div>
      </Fade>
      {/* <div className="repo-cards-div-main">
        {repos.map((repo, index) => {
          return <GithubProj repo={repo} key={index} theme={theme} />;
        })}
      </div> */}
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer theme={props.theme} onToggle={props.onToggle} />
      <FloatButton theme={props.theme} />
    </div>
  );
}

export default Products;
