import React from "react";
import Gallery from '../portfolioCaruousel/PortfolioCarousel'
import "./PortfolioCard.css";
import { Fade } from "react-reveal";
import { style } from "glamor";

export default function PortfolioCard({ addresso, theme }) {
  function openRepoinNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  const styles = style({
    color: "rgb(88, 96, 105)",
    backgroundColor: "rgb(255, 255, 255)",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 10px 30px -15px",
    padding: "2rem",
    // cursor: "pointer",
    borderRadius: "5px",
    height: "100%",
    transition: "all 0.2s ease-in-out",
    ":hover": {
      boxShadow: `${theme.imageDark} 0 2px 15px`,
    },
  });

  return (
    <div>
      <Fade bottom duration={2000} distance="40px">
        <div
          {...styles}
          key={addresso.id}
          style={{ backgroundColor: theme.projectCard }}
        >
          <div className="repo-name-div-portfolio">
            <p className="repo-name" style={{ color: theme.text }}>
              {addresso.name}
            </p>
          </div>
          <br />
          <div>
            <Gallery imgss={addresso.imgurl} />
          </div>
          <div>
            <button style={{backgroundColor: theme.primaryColor}} className="site-url" onClick={() => openRepoinNewTab(addresso.url)}>
              Visit Website
            </button>
          </div>
        </div>
      </Fade>
    </div>
  );
}
