import React from "react";
import './LocationMap.css'

export default function LocationMap() {

  return (
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2407.7171438232986!2d73.1132913896252!3d31.413403140859746!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5ccfaa7e5be52e7e!2sAinzics%20Web-Development%20Company!5e0!3m2!1sen!2s!4v1675409059111!5m2!1sen!2s"
        height={500}
        title="AINZICS Location"
        width="100%"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        className="location-map"
      ></iframe>
  );
}
