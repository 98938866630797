import React from "react";
import "./FaqSection.css";
import { frequentQuestions } from "../../portfolio";
import Faqs from "../../components/faqs/Faqs";
import Whatsapp from "../../components/whatsapp/Whatsapp";

const FaqSection = (props) => {
  const theme = props.theme;
  return (
    <div class="faq-grid-section">
      <article class="faq-section-main">
        <div>
          <h1 className="faq-heading-text-query" style={{ color: theme.greetColor }}>
            Still Any Query?
          </h1>
          <h3 className="faq-heading-sub-text" style={{ color: theme.text }}>
            Schedule One-To-One Meeting on Just a Single Click.
          </h3>
        </div>
        <div
          className="faq-query-button-div heart-beat-effect"
          style={{ backgroundColor: theme.primaryColor }}
        >
          <a href={Whatsapp} className="faq-query-button">
            <Whatsapp theme={theme} padding={"24px 58px"} />
          </a>
        </div>
      </article>
      <section class="faq-section-side">
        <h1 className="faq-heading-text-query" style={{ color: theme.secondaryText }}>
          FAQs
        </h1>
        <h3 className="faq-heading-sub-text" style={{ color: theme.text }}>
          Best Solutions at Competetive Prices
        </h3>
        {frequentQuestions.question.map((prof) => {
          return <Faqs que={prof} key={prof.id} theme={theme} />;
        })}
      </section>
    </div>
  );
};

export default FaqSection;
