import React from "react";

export default function PortfolioImg(props) {
  const theme = props.theme;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      width="717.67003"
      height="556.32201"
      viewBox="0 0 717.67003 556.32201"
    >
      <path
        d="M338.564,710.8789l7.71-12.39,1.54,7.08008c.27,1.24.54,2.5.79,3.75,2.18994-1.87012,4.52-3.6001,6.7998-5.26q10.5-7.62021,20.99024-15.26l-2.18994,12.7c-1.3501,7.82007-2.76026,15.8-6.1001,22.95-.37012.81005-.77,1.6101-1.2002,2.39013h-32.54a10.48578,10.48578,0,0,1-.54-2.24011.7735.7735,0,0,1-.00977-.15C333.30371,719.59887,335.98388,715.02886,338.564,710.8789Z"
        transform="translate(-241.16498 -171.839)"
        fill={theme.accentColor}
      />
      <path
        d="M828.55938,171.839H370.562a14.86759,14.86759,0,0,0-14.84394,14.84382V495.74139A14.85811,14.85811,0,0,0,370.562,510.5852H828.55938a14.85813,14.85813,0,0,0,14.84394-14.84381V186.68278A14.86759,14.86759,0,0,0,828.55938,171.839Z"
        transform="translate(-241.16498 -171.839)"
        fill="#292929"
      />
      <path
        d="M825.73663,500.35262H525.64093A159.15669,159.15669,0,0,1,366.95,341.66122v-151.146a8.45605,8.45605,0,0,1,8.43847-8.45459H825.73663a8.456,8.456,0,0,1,8.43847,8.45459V491.91415A8.44792,8.44792,0,0,1,825.73663,500.35262Z"
        transform="translate(-241.16498 -171.839)"
        fill="#ffffff"
      />
      <path
        d="M802.0982,224.08639H380.70944a1.67615,1.67615,0,0,1,0-3.35149H802.0982a1.67615,1.67615,0,0,1,0,3.35149Z"
        transform="translate(-241.16498 -171.839)"
        fill="#292929"
      />
      <ellipse
        cx="168.22892"
        cy="30.30038"
        rx="9.82066"
        ry="10.03952"
        fill="#d04f4f"
      />
      <ellipse
        cx="202.15484"
        cy="30.30038"
        rx="9.82066"
        ry="10.03952"
        fill="#d3b04a"
      />
      <ellipse
        cx="236.08074"
        cy="30.30038"
        rx="9.82066"
        ry="10.03952"
        fill="#78ae46"
      />
      <path
        d="M667.68109,374.72872H497.03265a12.21076,12.21076,0,0,1-12.32422-11.18066,12.00672,12.00672,0,0,1,11.1666-12.79181q.40247-.02729.80606-.02753h171a12,12,0,0,1,0,24Z"
        transform="translate(-241.16498 -171.839)"
        fill={theme.accentColor}
      />
      <circle cx="426.83214" cy="124.84145" r="10.8415" fill="#e6e6e6" />
      <path
        d="M666.51812,302.323a1.2056,1.2056,0,0,1-.96466-.48248l-2.9577-3.9437a1.20594,1.20594,0,1,1,1.92956-1.447l1.935,2.57986,4.96991-7.45477a1.206,1.206,0,0,1,2.01172,1.33069l-.00482.00723-5.91547,8.87317a1.20657,1.20657,0,0,1-.97.53668Z"
        transform="translate(-241.16498 -171.839)"
        fill="#fff"
      />
      <circle cx="426.83214" cy="256.84144" r="10.84149" fill="#e6e6e6" />
      <path
        d="M666.51812,434.323a1.2056,1.2056,0,0,1-.96466-.48248l-2.9577-3.9437a1.20594,1.20594,0,1,1,1.92956-1.447l1.935,2.57986,4.96991-7.45477a1.206,1.206,0,0,1,2.01172,1.33069l-.00482.00723-5.91547,8.87317a1.20657,1.20657,0,0,1-.97.53668Z"
        transform="translate(-241.16498 -171.839)"
        fill="#fff"
      />
      <path
        d="M580.68109,308.72872H497.03265a12.21076,12.21076,0,0,1-12.32422-11.18066,12.00672,12.00672,0,0,1,11.1666-12.79181q.40247-.02729.80606-.02753h84a12,12,0,0,1,0,24Z"
        transform="translate(-241.16498 -171.839)"
        fill={theme.accentColor}
      />
      <path
        d="M667.68109,309.22872h-171a12.5,12.5,0,0,1,0-25h171a12.5,12.5,0,0,1,0,25Zm-171-23a10.5,10.5,0,0,0,0,21h171a10.5,10.5,0,0,0,0-21Z"
        transform="translate(-241.16498 -171.839)"
        fill="#3f3d56"
      />
      <path
        d="M667.68109,375.22872h-171a12.5,12.5,0,0,1,0-25h171a12.5,12.5,0,0,1,0,25Zm-171-23a10.5,10.5,0,0,0,0,21h171a10.5,10.5,0,0,0,0-21Z"
        transform="translate(-241.16498 -171.839)"
        fill="#3f3d56"
      />
      <path
        d="M544.68109,440.72872H497.03265a12.21076,12.21076,0,0,1-12.32422-11.18066,12.00672,12.00672,0,0,1,11.1666-12.79181q.40247-.02729.80606-.02753h48a12,12,0,0,1,0,24Z"
        transform="translate(-241.16498 -171.839)"
        fill={theme.accentColor}
      />
      <path
        d="M667.68109,441.22872h-171a12.5,12.5,0,0,1,0-25h171a12.5,12.5,0,0,1,0,25Zm-171-23a10.5,10.5,0,0,0,0,21h171a10.5,10.5,0,0,0,0-21Z"
        transform="translate(-241.16498 -171.839)"
        fill="#3f3d56"
      />
      <path
        d="M528.68188,271.839h-40a4,4,0,0,1,0-8h40a4,4,0,0,1,0,8Z"
        transform="translate(-241.16498 -171.839)"
        fill="#ccc"
      />
      <path
        d="M528.68188,337.839h-40a4,4,0,0,1,0-8h40a4,4,0,0,1,0,8Z"
        transform="translate(-241.16498 -171.839)"
        fill={theme.accentColor}
      />
      <path
        d="M528.68188,403.839h-40a4,4,0,0,1,0-8h40a4,4,0,0,1,0,8Z"
        transform="translate(-241.16498 -171.839)"
        fill="#ccc"
      />
      <ellipse
        cx="227.52793"
        cy="96.41727"
        rx="4.34647"
        ry="4.44332"
        fill="#3f3d56"
      />
      <ellipse
        cx="227.52793"
        cy="162.41727"
        rx="4.34647"
        ry="4.44331"
        fill="#3f3d56"
      />
      <ellipse
        cx="227.52793"
        cy="228.41727"
        rx="4.34647"
        ry="4.44331"
        fill="#3f3d56"
      />
      <polygon
        points="513.946 544.191 504.045 544.19 499.335 506 513.948 506.001 513.946 544.191"
        fill="#ffb6b6"
      />
      <path
        d="M755.45947,726.35863l-30.447-.00115v-.385a11.85147,11.85147,0,0,1,11.85083-11.85065h.00073l5.56152-4.21924,10.37659,4.21991,2.65784.00006Z"
        transform="translate(-241.16498 -171.839)"
        fill="#2f2e41"
      />
      <polygon
        points="552.946 544.191 543.045 544.19 538.335 506 552.948 506.001 552.946 544.191"
        fill="#ffb6b6"
      />
      <path
        d="M794.45947,726.35863l-30.447-.00115v-.385a11.85147,11.85147,0,0,1,11.85083-11.85065h.00073l5.56152-4.21924,10.37659,4.21991,2.65784.00006Z"
        transform="translate(-241.16498 -171.839)"
        fill="#2f2e41"
      />
      <path
        d="M721.03936,476.839l-7,24,8.24646,101.6449s-4.24646,6.3551-1.24646,6.3551,6.84687,1.40943,4.4234,5.20471,5.86,17.4209,5.86,17.4209L743.03936,703.839l14.92914-.22046s7.59009-4.22833,3.33051-8.504-7.25959-4.27558-3.25959-8.27558,4-2.79529,4-5.89764,4.00751-41.01575-5.49627-55.559l2.86364-59.29529,6.74457,58.16144,13.888,77.59051,14.38172-.11847s6.64276-6.40271,6.63049-11.14209-4.10419-5.13312-1.05823-7.93628,3.046-45.86615-5.954-69.80316l.06885-107.07113s8.84271-24.03068-.1131-33.47977S721.03936,476.839,721.03936,476.839Z"
        transform="translate(-241.16498 -171.839)"
        fill="#2f2e41"
      />
      <path
        d="M811.17968,434.96532l6.02893-62.44943a10.12681,10.12681,0,0,0-8.98034-11.00989h0a10.1268,10.1268,0,0,0-11.16907,8.96415q-.0293.26706-.04431.53537L792.741,436.06719l-4.26349,39.42441a8.83707,8.83707,0,1,0,10.42536,4.46579Z"
        transform="translate(-241.16498 -171.839)"
        fill="#ffb6b6"
      />
      <path
        d="M788.57159,340.86092l21.72247,2.72333s16.27588,30.67844,12.926,33.282-18.488,18.84656-29.64844,11.40043S788.57159,340.86092,788.57159,340.86092Z"
        transform="translate(-241.16498 -171.839)"
        fill={theme.accentColor}
      />
      <circle cx="509.99813" cy="116.5323" r="23.0557" fill="#ffb6b6" />
      <path
        d="M760.07177,309.53719c-6.61615-.21707-7.2171-1.96463-13.83325-2.1817-2.10663-.06912-4.59253-.35785-5.611-2.20321a4.86245,4.86245,0,0,1,.24682-4.32813,39.51744,39.51744,0,0,0,2.03821-4.0094,7.00277,7.00277,0,0,0-6.93847-9.23236c-2.01337.087-3.9043,1.03381-5.91211,1.20685-2.75361.23733-8.84247-8.85434-7.57471-11.31021,1.23407-2.39057,3.71753-4.19419,4.10791-6.856.25531-1.741-.46332-3.4708-.56152-5.22763-.14557-2.60492,6.45758,3.26858,5.24823.95682-2.96644-5.671,24.98754-12.70145,29.58972-5.59806,1.72,2.65485,4.78534,2.47376,7.51751,4.06815,1.79071,1.045,6.31232,4.65008,7.34028,6.89709a17.49431,17.49431,0,0,1,1.30938,7.23868c.04138,11.52646-1.71466,22.129-11.012,28.94229"
        transform="translate(-241.16498 -171.839)"
        fill="#2f2e41"
      />
      <path
        d="M745.03936,317.839l19-4,14.59387,7.0195s34.51886,13.88529,34.40143,27.57651-6.18445,43.69122-13.15094,46.69122,6.03351,13,2.03351,19-10.059,46.99091-10.059,46.99091,14.16522,28.218,2.181,33.72189-62.62915,10.64942-65.87561,3.46832-3.27655-3.26229-10.76147-5.22171.00793-29.43182,6.76147-32.19561,10.75354-62.7638,10.75354-62.7638l-10.75354-46.65356L740.5708,328.378Z"
        transform="translate(-241.16498 -171.839)"
        fill={theme.accentColor}
      />
      <path
        d="M731.73162,446.07827l17.16584-60.34579a10.1268,10.1268,0,0,0-6.853-12.44587h0a10.12681,10.12681,0,0,0-12.59955,6.80848q-.07681.25744-.13989.51864l-15.90937,63.23114L702.10882,481.859a8.83714,8.83714,0,1,0,9.45178,6.26856Z"
        transform="translate(-241.16498 -171.839)"
        fill="#ffb6b6"
      />
      <path
        d="M726.42266,349.442l20.87812,6.587s13.57959,19.94034,8.496,22.7851-6.99091,2.243-4.37976,7.26117,6.37475,3.05981,2.61114,5.01816-21.577,15.21286-31.2157,5.88037S726.42266,349.442,726.42266,349.442Z"
        transform="translate(-241.16498 -171.839)"
        fill={theme.accentColor}
      />
      <path
        d="M241.165,726.971a1.18646,1.18646,0,0,0,1.18292,1.19H957.645a1.19,1.19,0,1,0,0-2.38H242.355a1.18649,1.18649,0,0,0-1.19,1.183Z"
        transform="translate(-241.16498 -171.839)"
        fill="#ccc"
      />
      <circle
        cx="426.83214"
        cy="190.84145"
        r="20.41196"
        fill={theme.accentColor}
      />
      <path
        d="M665.21252,373.304a2.26986,2.26986,0,0,1-1.81623-.9084l-5.56865-7.425a2.2705,2.2705,0,0,1,3.63291-2.72446l3.64314,4.85727,9.35715-14.03556a2.27061,2.27061,0,0,1,3.78759,2.50537l-.00908.01362-11.13741,16.70606a2.2717,2.2717,0,0,1-1.82622,1.01044Z"
        transform="translate(-241.16498 -171.839)"
        fill="#fff"
      />
    </svg>
  );
}
