import React from "react";

export default function PhoneImg(props) {
  const theme = props.theme;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      version="1.1"
      viewBox="0 0 302 302"
    >
      <defs>
        <clipPath id="clipPath3142" clipPathUnits="userSpaceOnUse"></clipPath>
        <mask id="mask3949" maskUnits="userSpaceOnUse">
          <g transform="matrix(1.5061 0 0 .96932 -361.99 -546.05)">
            <path
              fill="none"
              stroke="#c9ffc6"
              strokeLinejoin="round"
              strokeWidth="24.045"
              d="M291.16 1193.5s19.69 11 26.35 17.84c10 10.27 19.05 52.98 25.7 40.28 40.2-76.76 58.12-273.25 58.12-273.25s.37 125.39 7.43 178.53c5.34 40.26 19.67 149.71 29.87 132.02 17.02-29.51 22.63-87.16 32.97-126.65 4.95-18.92 18.63 37.97 31.23 57.26 5.72 8.76 4.96-33.19 15.61-32.1 26.03 2.66 27.76.87 27.76.87"
            ></path>
          </g>
        </mask>
      </defs>
      <g transform="translate(0 -750.36)">
        <path
          fill={theme.iconsBg}
          d="M462.86 553.79a194.29 195.71 0 11-388.57 0 194.29 195.71 0 11388.57 0z"
          transform="matrix(.77206 0 0 .76642 -56.353 476.92)"
        ></path>
        <path
          fill="#afe9af"
          d="M462.86 553.79a194.29 195.71 0 11-388.57 0 194.29 195.71 0 11388.57 0z"
          clipPath="url(#clipPath3142)"
          transform="matrix(.77206 0 0 .76642 -56.353 476.92)"
        ></path>
        <path
          fill="#050"
          d="M462.86 553.79a194.29 195.71 0 11-388.57 0 194.29 195.71 0 11388.57 0z"
          mask="url(#mask3949)"
          transform="matrix(.77206 0 0 .76642 -56.353 476.92)"
        ></path>
        <path
          fill={theme.primaryColor}
          d="M193.79 997.18c8.493-2.647 17.763-6.24 20.884-8.094 2.097-1.246 2.392-1.666 1.667-2.369-5.257-5.091-42.235-37.49-42.561-37.289-.239.148-.842 2.457-1.342 5.132-.985 5.281-1.914 6.49-4.985 6.488-2.528 0-4.195-1.418-6.62-5.624-1.056-1.833-2.764-4.414-3.795-5.735-1.031-1.321-3.115-4.621-4.631-7.334-1.516-2.713-4.18-7.183-5.92-9.933-3.99-6.305-8.632-13.991-10.389-17.199-.743-1.358-2.577-4.127-4.076-6.152-1.498-2.025-3.409-5.025-4.246-6.667-.836-1.642-3.234-5.535-5.329-8.652-2.094-3.117-4.611-7.252-5.593-9.19-.981-1.937-2.784-5.087-4.005-7-2.809-4.399-6.079-10.936-6.644-13.281-.344-1.43-.054-2.16 1.404-3.532 1.967-1.851 2.096-1.85 8.353.035 2.116.637 3.985 1.022 4.153.854.301-.301-14.017-55.8-14.53-56.31-.374-.376-17.14 13.704-17.14 14.395 0 .313-1.458 2.021-3.242 3.796-1.783 1.775-4.314 4.928-5.624 7.007-2.066 3.277-2.48 4.651-3.12 10.335-2.03 18.037 2.728 44.7 11.758 65.893 4.861 11.408 7.781 17.128 11.233 21.998 1.561 2.202 3.661 5.654 4.668 7.67 5.406 10.837 22.444 30.592 34.334 39.81 8.204 6.362 25.615 17.739 29.639 19.369 11.768 4.765 14.87 4.956 25.7 1.58zm30.013-20.446c2.551-6.27 2.622-6.636 1.5-7.694-.947-.894-30.821-26.907-34.944-30.428-.964-.823-1.932-.519-7.926 2.489-5.75 2.885-6.681 3.567-5.902 4.317 2.844 2.74 44.239 38.254 44.4 38.093.107-.107 1.399-3.156 2.872-6.776zm-91.81-119.9c2.827-2.153 5.132-3.953 5.122-4-.767-3.574-11.94-47.268-12.136-47.466-.384-.384-14.644-2.384-14.934-2.095-.181.181 3.734 15.317 14.482 55.991.761 2.882.288 3.036 7.466-2.43z"
        ></path>
      </g>
    </svg>
  );
}
