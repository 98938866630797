import React from "react";
import Carousel from "react-grid-carousel";

const Gallery = (props) => {
  return (
    <Carousel
      cols={1}
      rows={1}
      gap={50}
      containerStyle={{marginRight: '0px', marginLeft: '0px', paddingLeft: '0px'}}
      loop
      autoplay={3000}
      showDots
      dotColorActive="#19ed95"
    >
      {props.imgss.map((imgs) => {
        return (
          <Carousel.Item key={imgs.id}>
            <img
              width="100%"
              alt={require(`../../assests/images/portfolio/${imgs.url}`)}
              src={require(`../../assests/images/portfolio/${imgs.url}`)}
            />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default Gallery;
