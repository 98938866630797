import React from "react";

export default function TwitterImg(props) {
  const theme = props.theme;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill={theme.iconsBg}></circle>
      <path
        fill={theme.primaryColor}
        d="M28 15.067c-.6.266-1.2.466-1.867.533a3.55 3.55 0 001.467-1.8c-.667.4-1.333.667-2.067.8a3.227 3.227 0 00-2.4-1.067 3.272 3.272 0 00-3.266 3.267c0 .267 0 .533.066.733a9.205 9.205 0 01-6.8-3.466 3.01 3.01 0 00-.466 1.666c0 1.134.6 2.134 1.466 2.734-.533 0-1.066-.134-1.466-.4v.066c0 1.6 1.133 2.934 2.6 3.2-.267.067-.534.134-.867.134-.2 0-.4 0-.6-.067a3.16 3.16 0 003.067 2.267c-1.134.866-2.534 1.4-4.067 1.4-.267 0-.533 0-.8-.067a9.332 9.332 0 005 1.467c6.067 0 9.333-5 9.333-9.334v-.4c.667-.466 1.2-1.066 1.667-1.666z"
      ></path>
    </svg>
  );
}
