import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import BlogsImg from "./BlogsImg";
// import Myprofiles from "../../containers/profiles/Myprofiles";
import { Fade } from "react-reveal";
import "./ContactComponent.css";
import { contactPageData } from "../../portfolio.js";
import { style } from "glamor";
import ContactFormFile from "../../components/contactFormFile/ContactFormFile";
import FloatButton from "../../components/floatingButton/FloatButton";
import LocationMap from '../../components/locationMap/LocationMap'

const ContactData = contactPageData.contactSection;
const blogSection = contactPageData.blogSection;

function Contact(props) {
  const theme = props.theme;

  const styles = style({
    backgroundColor: `${theme.accentBright}`,
    ":hover": {
      boxShadow: `0 5px 15px ${theme.accentBright}`,
    },
  });

  return (
    <div>
      <Header theme={theme} setTheme={props.setTheme} />
      <div className="basic-contact">
        <Fade bottom duration={1000} distance="40px">
          <div className="contact-heading-div">
            {/* <div className="contact-heading-img-div">
              <img
                className="profile-pic"
                src={require(`../../assests/images/${ContactData["profile_image_path"]}`)}
                alt=""
              />
            </div> */}
            <div className="contact-heading-text-div">
              <h1
                className="contact-heading-text"
                style={{ color: theme.secondaryText }}
              >
                {ContactData["title"]}
              </h1>
              <p
                className="contact-header-detail-text subTitle"
                style={{ color: theme.text }}
              >
                {ContactData["description"]}
              </p>
              <SocialMedia />
              <br />
              <br />
              {/* <a {...styles} className="general-btn" href={greeting.resumeLink}>
                See my Resume
              </a> */}
            </div>
          </div>
        </Fade>
        <Fade bottom duration={1000} distance="40px">
          <ContactFormFile  theme={theme}/>
        </Fade>
        <Fade bottom duration={1000} distance="40px">
          <LocationMap />
        </Fade>
        {/* <Myprofiles theme={props.theme} /> */}
        <Fade bottom duration={1000} distance="40px">
          <div className="blog-heading-div">
            <div className="blog-heading-text-div">
              <h1 className="blog-heading-text" style={{ color: theme.secondaryText }}>
                {blogSection["title"]}
              </h1>
              <p
                className="blog-header-detail-text subTitle"
                style={{ color: theme.text }}
              >
                {blogSection["subtitle"]}
              </p>
              <div className="blogsite-btn-div">
                <a style={{backgroundColor: theme.primaryColor}} {...styles} className="general-btn" href={blogSection.link}>
                  Coming Soon
                </a>
              </div>
            </div>
            <div className="blog-heading-img-div">
              <BlogsImg theme={theme} />
            </div>
          </div>
        </Fade>
      </div>
      <Footer theme={props.theme} onToggle={props.onToggle} />
      <FloatButton theme={props.theme} />
    </div>
  );
}

export default Contact;
