import React from "react";

export default function CommerceImg(props) {
  const theme = props.theme;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="879.125"
      height="631.485"
      data-name="Layer 1"
      viewBox="0 0 879.125 631.485"
    >
      <path
        fill="#e6e6e6"
        d="M0.275 0.365H645V413.27500000000003H0.275z"
        data-name="Rectangle 338"
      ></path>
      <path
        fill="#fff"
        d="M18.711 52.154H626.564V388.847H18.711z"
        data-name="Rectangle 339"
      ></path>
      <path
        fill={theme.accentColor}
        d="M0 0H644.725V27.39H0z"
        data-name="Rectangle 340"
      ></path>
      <circle
        cx="20.355"
        cy="14.004"
        r="5.077"
        fill="#fff"
        data-name="Ellipse 513"
      ></circle>
      <circle
        cx="39.624"
        cy="14.004"
        r="5.077"
        fill="#fff"
        data-name="Ellipse 514"
      ></circle>
      <circle
        cx="58.893"
        cy="14.004"
        r="5.077"
        fill="#fff"
        data-name="Ellipse 515"
      ></circle>
      <path
        fill="#e6e6e6"
        d="M93.5 79.848H212.387V208.82999999999998H93.5z"
        data-name="Rectangle 341"
      ></path>
      <path
        fill="#e6e6e6"
        d="M263.193 79.848H382.08V208.82999999999998H263.193z"
        data-name="Rectangle 342"
      ></path>
      <path
        fill="#e6e6e6"
        d="M432.885 79.848H551.7719999999999V208.82999999999998H432.885z"
        data-name="Rectangle 343"
      ></path>
      <path
        fill="#e6e6e6"
        d="M93.5 232.176H212.387V361.158H93.5z"
        data-name="Rectangle 344"
      ></path>
      <path
        fill="#e6e6e6"
        d="M263.193 232.176H382.08V361.158H263.193z"
        data-name="Rectangle 345"
      ></path>
      <path
        fill="#e6e6e6"
        d="M432.885 232.176H551.7719999999999V361.158H432.885z"
        data-name="Rectangle 346"
      ></path>
      <path
        fill="#fff"
        d="M452.365 399.583l21.722-8.588 2.526 42.939s4.546 13.134 3.031 18.186c0 0 1.01 8.588-1.516 9.093s-9.6 1.516-10.1 1.01-.505-2.021-.505-2.021-5.052 3.536-5.557 7.577c0 0-21.217 6.567-21.722.505s10.608-11.114 10.608-11.114l8.588-13.134z"
        data-name="Path 2643"
        transform="translate(-160.437 -134.258)"
      ></path>
      <path
        fill="#fff"
        d="M498.337 399.583l21.72-8.588 2.525 42.939s4.546 13.134 3.031 18.186c0 0 1.01 8.588-1.516 9.093s-9.6 1.516-10.1 1.01-.505-2.021-.505-2.021-5.052 3.536-5.557 7.577c0 0-21.217 6.567-21.722.505s10.608-11.114 10.608-11.114l8.588-13.134z"
        data-name="Path 2644"
        transform="translate(-160.437 -134.258)"
      ></path>
      <path
        fill="#3f3d56"
        d="M500.46 233.768s-4.625 12.924.734 18.752l-4.469 15.353s15.874 47.622 11.34 54.274c0 0-16.933 8.466-50.8-3.024l13.756-53.367-1.965-22.526 2.721-10.583 6.047-.6s-3.628 13.606 3.024 15.723 13.141-15.383 13.141-15.383z"
        data-name="Path 2645"
        transform="translate(-160.437 -134.258)"
      ></path>
      <path
        fill="#fff"
        d="M286.858 317.195l5.837 2.388 27.858-36.613 8.755 42.98 5.837-1.061c3.744-37.055 6.8-74.85 3.051-92.726l-21.623-.93-9.816 31.312-4.776 21.225z"
        data-name="Path 2646"
        transform="translate(-160.437 -134.258)"
      ></path>
      <path
        fill="#fff"
        d="M641.115 231.87s-7.966 11.074-7.577 15.543 5.44 77.327 5.44 77.327l11.852.583-3.5-52.458 2.914-14.183 7.19 66.058 14.571.389-13.017-85.681-2.526-7.577z"
        data-name="Path 2647"
        transform="translate(-160.437 -134.258)"
      ></path>
      <path
        fill="#3f3d56"
        d="M686.138 410.276c-2.115-2.538-5.751-3.91-9.242-4.652.1-.317-10.618-2.532-10.8-2.076l-4.041-3.22-12.417 6.448-6.977-4.75-3.241 1.15c.132-.572-10.061 1.194-10.061 1.194-1.947.07-4.434.192-7.218.406-9.75.75-10.5 26-10.5 26a39.85 39.85 0 0114.113-2.955l2.635 30.2c13.553-1.162 27.767.19 42.5 3.5l6.909-23.74 16.089-6.257s-2.75-15.25-7.75-21.248z"
        data-name="Path 2648"
        transform="translate(-160.437 -134.258)"
      ></path>
      <path
        fill="#fff"
        d="M340.878 395.895c-1.743-2.091-4.738-3.222-7.615-3.833.086-.262-8.749-2.086-8.9-1.71l-3.33-2.652-10.229 5.312-5.748-3.913-2.67.948c.108-.471-8.29.983-8.29.983-1.6.058-3.655.158-5.948.335-8.033.618-8.65 21.42-8.65 21.42a32.837 32.837 0 0111.628-2.434l2.171 60.914c11.167-.958 22.88.157 35.015 2.884l5.692-55.59 13.257-5.155s-2.264-12.564-6.383-17.51z"
        data-name="Path 2649"
        transform="translate(-160.437 -134.258)"
      ></path>
      <path
        fill="#2f2e41"
        d="M909.888 412.109h57v-30.5a28.5 28.5 0 10-57 0z"
        data-name="Path 2682"
        transform="translate(-160.437 -134.258)"
      ></path>
      <path
        fill="#ffb6b6"
        d="M884.442 751.453h-12.259l-5.833-47.292h18.094z"
        data-name="Path 2683"
        transform="translate(-160.437 -134.258)"
      ></path>
      <path
        fill="#2f2e41"
        d="M887.57 747.45h-24.145a15.387 15.387 0 00-15.386 15.385v.5h39.53z"
        data-name="Path 2684"
        transform="translate(-160.437 -134.258)"
      ></path>
      <path
        fill="#ffb6b6"
        d="M1030.997 735.092l-10.676 6.027-28.328-38.311 15.757-8.895z"
        data-name="Path 2685"
        transform="translate(-160.437 -134.258)"
      ></path>
      <path
        fill="#2f2e41"
        d="M1031.752 730.069l-21.025 11.866a15.387 15.387 0 00-5.834 20.963l.246.435 34.424-19.433z"
        data-name="Path 2686"
        transform="translate(-160.437 -134.258)"
      ></path>
      <path
        fill="#2f2e41"
        d="M952.642 538.782l.946 4.73s2.838 2.838 1.42 4.257-.947 8.042-.947 8.042c.794 12.076 16.573 79.936 19.395 93.189 0 0 21.76 14.19 38.79 42.1s17.5 35.005 17.5 35.005l-18.919 8.043-47.3-57.238s-13.245-8.042-17.976-15.137-34.064-80.418-34.064-80.418l-16.56 76.633s.946 27.909-3.31 45.885a192.522 192.522 0 00-4.73 34.532l-22.234-2.365s1.42-67.172 3.784-74.268c0 0-9.934-81.836 9.934-114.476l16.012-42.687 15.682-6.036z"
        data-name="Path 2687"
        transform="translate(-160.437 -134.258)"
      ></path>
      <path
        fill="#ffb8b8"
        d="M938.682 360.565c-29.847.51-29.842 44.907 0 45.412 29.845-.51 29.84-44.907 0-45.412z"
        data-name="Path 2688"
        transform="translate(-160.437 -134.258)"
      ></path>
      <path
        fill="#2f2e41"
        d="M915.71 382.093h5.074l1.38-3.454-.69 3.454h27.745l-.9-7.227 6.744 7.227h6.727v-3.916a23.038 23.038 0 10-46.076-.033v.033z"
        data-name="Path 2689"
        transform="translate(-160.437 -134.258)"
      ></path>
      <path
        fill="#3f3d56"
        d="M907.904 419.235l56.4.457-11.916 120.917s-65.96-3.481-66-23.614l6.178-17.891z"
        data-name="Path 2690"
        transform="translate(-160.437 -134.258)"
      ></path>
      <path
        fill="#ffb6b6"
        d="M966.727 552.298a9.377 9.377 0 012.7-14.122l-2.45-21.287 12.573-4.645 3.039 30.11a9.428 9.428 0 01-15.862 9.944z"
        data-name="Path 2693"
        transform="translate(-160.437 -134.258)"
      ></path>
      <path
        fill="#3f3d56"
        d="M956.888 430.109l7.42-10.373s5.08-1.127 11.08 5.873 9.5 94.5 9.5 94.5l-22 5-13-62z"
        data-name="Path 2694"
        transform="translate(-160.437 -134.258)"
      ></path>
      <path
        fill="#fff"
        d="M672.135 390.739L673.771 468.476 724.096 495.071 815.744 478.705 817.995 397.899 759.896 377.441 672.135 390.739z"
      ></path>
      <path
        fill="#cacaca"
        d="M723.965 495.91l-50.987-26.944-1.662-78.916 88.658-13.433.19.066 58.65 20.652-2.284 82.046zm-49.4-27.924l49.662 26.245 90.732-16.202 2.216-79.567-57.356-20.196-86.866 13.161z"
      ></path>
      <path
        fill="#cacaca"
        d="M722.973 411.816L722.776 411.738 671.838 391.485 672.431 389.992 723.173 410.166 817.885 397.103 818.105 398.695 722.973 411.816z"
      ></path>
      <path
        fill="#cacaca"
        d="M883.218 545.246H884.8249999999999V629.332H883.218z"
        transform="rotate(-.697 -10233.301 13709.45)"
      ></path>
      <path
        fill="#cacaca"
        d="M716.536 407.792L698.174 400.348 786.569 386.263 804.931 393.706 716.536 407.792z"
      ></path>
      <path
        fill="#cacaca"
        d="M842.417 541.277H844.024V564.4630000000001H842.417z"
        transform="rotate(-66.706 661.012 607.618)"
      ></path>
      <path
        fill={theme.accentColor}
        d="M852.06 567.797H853.6669999999999V590.9830000000001H852.06z"
        transform="rotate(-66.706 670.656 634.138)"
      ></path>
      <path
        fill="#fff"
        d="M392.135 498.739L393.771 576.476 444.096 603.071 535.744 586.705 537.995 505.899 479.896 485.441 392.135 498.739z"
      ></path>
      <path
        fill="#cacaca"
        d="M443.965 603.91l-50.987-26.944-1.662-78.916 88.658-13.433.19.066 58.65 20.652-2.284 82.046zm-49.4-27.924l49.662 26.245 90.732-16.202 2.216-79.567-57.356-20.196-86.866 13.161z"
      ></path>
      <path
        fill="#cacaca"
        d="M442.973 519.816L442.776 519.738 391.838 499.485 392.431 497.992 443.173 518.166 537.885 505.103 538.105 506.695 442.973 519.816z"
      ></path>
      <path
        fill="#cacaca"
        d="M603.218 653.246H604.8249999999999V737.332H603.218z"
        transform="rotate(-.697 -10513.3 13817.45)"
      ></path>
      <path
        fill="#cacaca"
        d="M432.088 513.962L418.174 508.348 506.569 494.263 520.482 499.877 432.088 513.962z"
      ></path>
      <path
        fill="#cacaca"
        d="M562.417 649.277H564.024V672.4630000000001H562.417z"
        transform="rotate(-66.706 381.012 715.618)"
      ></path>
      <path
        fill={theme.accentColor}
        d="M572.06 675.797H573.6669999999999V698.9830000000001H572.06z"
        transform="rotate(-66.706 390.656 742.138)"
      ></path>
      <path
        fill={theme.accentColor}
        d="M479.563 509.397L481.463 599.638 539.882 630.51 646.271 611.512 648.883 517.709 581.44 493.961 479.563 509.397z"
      ></path>
      <path
        fill="#3f3d56"
        d="M539.73 631.485l-59.189-31.279-1.928-91.608 102.917-15.594.22.077 68.085 23.974-2.653 95.241zm-57.346-32.416l57.65 30.466 105.325-18.808 2.572-92.364-66.581-23.445-100.837 15.279z"
      ></path>
      <path
        fill="#3f3d56"
        d="M538.578 533.865L538.35 533.774 479.218 510.264 479.907 508.53 538.81 531.949 648.755 516.785 649.01 518.633 538.578 533.865z"
      ></path>
      <path
        fill="#3f3d56"
        d="M698.792 667.161H700.658V764.771H698.792z"
        transform="rotate(-.697 -10417.597 13838.127)"
      ></path>
      <path
        fill="#3f3d56"
        d="M526.888 527.112L509.79 520.553 612.402 504.202 629.501 510.761 526.888 527.112z"
      ></path>
      <path
        fill="#3f3d56"
        d="M651.428 662.554H653.294V689.47H651.428z"
        transform="rotate(-66.706 470.153 730.76)"
      ></path>
      <path
        fill="#fff"
        d="M662.623 693.339H664.489V720.2550000000001H662.623z"
        transform="rotate(-66.706 481.348 761.545)"
      ></path>
      <path
        fill="#ffb6b6"
        d="M861.105 537.85a9.377 9.377 0 003.673-13.9l11.422-18.13-9.324-9.627-15.77 25.829a9.428 9.428 0 0010 15.83z"
        data-name="Path 2691"
        transform="translate(-160.437 -134.258)"
      ></path>
      <path
        fill="#3f3d56"
        d="M916.57 424l-8.182-5.391s-6.315 1.416-13.031 14.334-33.8 70.672-33.8 70.672l11.133 11.3 29.7-45.31z"
        data-name="Path 2692"
        transform="translate(-160.437 -134.258)"
      ></path>
    </svg>
  );
}
