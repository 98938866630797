import React, { useState } from "react";
import axios from "axios";
import "./ContactFormFile.css";
import TelegramImg from "../../components/svgImages/Telegram";
import PhoneImg from "../../components/svgImages/PhoneImg";
import EmailImg from "../../components/svgImages/EmailImg";
const ContactFormFile = (props) => {
  const theme = props.theme;
  const [textShow, setTextShow] = useState("none");
  const [disableBtn, setDisableBtn] = useState(false);
  const [filePath, setFile] = useState(null);
  const [inpVal, setInpVal] = useState({
    name: "",
    email: "",
    phone: "",
    text: "",
    message: "",
    subject: "",
  });
  const whereTo = "info";
  const setData = (e) => {
    // console.log(e.target.value);
    const { name, value } = e.target;
    setInpVal((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };
  const getData = (e) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_MAIL_SERVER}/api/sendEmail`, {
        ...inpVal,
        whereTo,
        filePath,
      })
      .then((data) => {
        console.log(data);

        setDisableBtn(false);
        setInpVal({
          name: "",
          email: "",
          phone: "",
          text: "",
          message: "",
          subject: "",
        });
        setFile("");
        setTextShow("block");
        setTimeout(() => {
          setTextShow("none");
        }, 4500);
      })
      .catch((error) => console.log(error));
  };
  return (
    <div className="Contact-main-div">
      <div className="contact-left">
        <ul className="ul-direction">
          <li>
            <div className="contact-icon">
              <TelegramImg theme={props.theme} />
            </div>
            <div className="contact-info">
              <h5 className="contact-address-headings">Address</h5>
              <p style={{ color: theme.text }} className="contact-address-para">
                Office # 82, 1st Floor, Mediacom Trade City Plaza, Kohinoor,
                Faisalabad, Pakistan
              </p>
            </div>
          </li>
          <li>
            <div className="contact-icon">
              <PhoneImg theme={props.theme} />
            </div>
            <div className="contact-info">
              <h5 className="contact-address-headings">Phone</h5>
              <p style={{ color: theme.text }} className="contact-address-para">
                +92-325-615-7500
              </p>
            </div>
          </li>
          <li>
            <div className="contact-icon">
              <EmailImg theme={props.theme} />
            </div>
            <div className="contact-info">
              <h5 className="contact-address-headings">E-Mail</h5>
              <p style={{ color: theme.text }} className="contact-address-para">
                admin@ainzics.com
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div className="contact-right">
        <form className="contact-form" onSubmit={getData}>
          {/* <table className="contactTable"> */}
          <div className="inputBox">
            <span className="inputCol">
              <input
                value={inpVal.name}
                onChange={setData}
                style={{ backgroundColor: theme.inputColor }}
                className="formInput"
                type="text"
                name="name"
                placeholder="Name"
                required
              />
            </span>
            <span className="inputCol">
              <input
                value={inpVal.email}
                onChange={setData}
                style={{ backgroundColor: theme.inputColor }}
                className="formInput"
                type="email"
                name="email"
                placeholder="Email"
                required
              />
            </span>
          </div>
          <div className="inputBox">
            <span className="inputCol">
              <input
                value={inpVal.phone}
                onChange={setData}
                style={{ backgroundColor: theme.inputColor }}
                className="formInput"
                type="text"
                name="phone"
                placeholder="Phone No."
                required
              />
            </span>
            <span className="inputCol">
              <input
                value={inpVal.subject}
                onChange={setData}
                style={{ backgroundColor: theme.inputColor }}
                className="formInput"
                type="text"
                name="subject"
                placeholder="Subject"
                required
              />
            </span>
          </div>
          <div className="inputBox">
            <span className="inputCol">
              <textarea
                name="message"
                value={inpVal.message}
                onChange={setData}
                style={{ backgroundColor: theme.inputColor }}
                className="formInput"
                rows="5"
                required
              />
            </span>
          </div>
          <div className="fileBox">
            <span
              className="attachFile"
              onClick={() => {
                const uploadFile = document.createElement("input");
                uploadFile.setAttribute("type", "file");
                uploadFile.click();
                uploadFile.addEventListener("change", (e) => {
                  const target = e.target;
                  // target && target.files && target.files[0]
                  const formData = new FormData();
                  formData.append("file", target.files[0]);
                  axios
                    .post(
                      `${process.env.REACT_APP_MAIL_SERVER}/api/inzamam/attachment`,
                      formData
                    )
                    .then((data) => {
                      console.log(data && data.data && data.data.file);
                      data &&
                        data.data &&
                        data.data.file &&
                        setFile(data && data.data && data.data.file);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                });
              }}
            >
              <span role="img" aria-label="You can Upload Document">
                📎
              </span>{" "}
              Attach Your File
              <p className="fileName">{(filePath && filePath.name) || ""}</p>
            </span>
          </div>
          <div className="inputBox" style={{ justifyContent: "center" }}>
            <span
              style={{
                display: `${textShow}`,
                textAlign: "center",
                paddingBottom: "15px",
              }}
            >
              Your Request have Sent Successfuly{" "}
              <span role="img" aria-label="Successfully uploaded">
                ✅
              </span>
              .
            </span>
          </div>
          <div className="inputBtn">
            <span className="inputCol">
              <button
                disabled={disableBtn}
                type="submit"
                className="contactBtn "
              >
                Submit
              </button>
            </span>
          </div>
          {/* </table> */}

          <div></div>
          <div></div>
          {/* <button></button> */}
        </form>
      </div>
    </div>
  );
};
export default ContactFormFile;
