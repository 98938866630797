import React from 'react'
import './FloatButton.css'
import Whatsapp from '../../components/whatsapp/Whatsapp'

const FloatButton = (props) => {
  const newLocal = Whatsapp;
  return (
    <div
      class="float-buttons-container"
    >
      <a
        style={{ backgroundColor: props.theme.primaryColor }}
        href={newLocal}
        class="icon one"
      >
        <Whatsapp theme={props.theme} padding={"18px 8px"} />
      </a>
      <a
        style={{ backgroundColor: props.theme.primaryColor }}
        href="https://t.me/ainzics"
        class="icon two"
        target="blank"
      >
        Get in touch
      </a>
      <a
        style={{ backgroundColor: props.theme.primaryColor }}
        href="https://t.me/ainzics"
        target='blank'
        class="icon three"
      >
        Send us a message
      </a>
    </div>
  );
}

export default FloatButton