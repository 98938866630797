import React from "react";
import Header from "../../components/header/Header";
import Greeting from "../../containers/greeting/Greeting";
import SpecialitiesCards from "../../containers/specialities/SpecialitiesCards";
// import TeamCards from '../../containers/teamCards/TeamCards'
// import ServiceCards from "../../containers/services/ServiceCards";
import FaqSection from "../../containers/faqss/FaqSection";
import Footer from "../../components/footer/Footer";
import JoinSectionContainer from "../../containers/joinSection/JoinSectionContainer";
import FloatButton from "../../components/floatingButton/FloatButton";


function Home(props) {
  return (
    <div>
      <Header theme={props.theme} setTheme={props.setTheme} />
      <Greeting theme={props.theme} />
      {/* <ServiceCards theme={props.theme} /> */}
      <SpecialitiesCards theme={props.theme} />
      {/* <TeamCards teamtitle={"Our Team"} theme={props.theme} /> */}
      <FaqSection theme={props.theme} />
      <FloatButton theme={props.theme} />
      <JoinSectionContainer theme={props.theme} />
      <Footer theme={props.theme} />
    </div>
  );
}

export default Home;
