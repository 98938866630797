import React from "react";
import "./JoinSection.css";
import { Fade } from "react-reveal"; 
import TelegramImg from "../svgImages/Telegram";
import TwitterImg from "../svgImages/TwitterImg";
import FacebookImg from "../svgImages/FacebookImg";
/* eslint-disable jsx-a11y/accessible-emoji */

export default function JoinSection(props) {
  const joinHeading = props.joindata;
  const theme = props.theme 
  return (
    <div className="join-section-div">
      <Fade>
        <h2 className="join-section-text" style={{ color: props.theme.text }}>
          {joinHeading.headingMain}
          <p className="join-section-sub-heading">{joinHeading.subHeading}</p>
        </h2>
        <p className="join-section-div-links">
          <a
            style={{
              borderColor: theme.secondaryText,
              color: theme.secondaryText,
            }}
            className="join-section-div-terms contact-anim"
            href="https://twitter.com/ainzics"
          >
            <span className="Span-display">
              <TwitterImg theme={props.theme} />
              Get Twitter Updates
            </span>
          </a>
          <a
            style={{
              borderColor: theme.secondaryText,
              color: theme.secondaryText,
            }}
            className="join-section-div-privacy contact-anim"
            href="https://facebook.com/ainzics"
          >
            <span className="Span-display">
              <FacebookImg theme={props.theme} />
              Join on Facebook
            </span>
          </a>
          <a
            style={{
              borderColor: theme.secondaryText,
              color: theme.secondaryText,
            }}
            className="join-section-div-privacy contact-anim"
            href="https://t.me/ainzics"
          >
            <span className="Span-display">
              <TelegramImg theme={props.theme} />
              Join the Telegram
            </span>
          </a>
        </p>
      </Fade>
    </div>
  );
}
