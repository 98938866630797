import React from "react";

export default function MobileappImg(props) {
  const theme = props.theme;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="405.67"
      height="457.176"
      viewBox="0 0 405.67 457.176"
    >
      <path
        fill="#ffb6b6"
        d="M155.533 279.994c2.305 4.463 1.872 9.27-.968 10.737-2.84 1.467-7.01-.962-9.316-5.424-1.01-1.954-1.493-3.973-1.48-5.75l-9.532-19.031 9.027-4.283 8.437 19.217c1.456 1.017 2.823 2.58 3.832 4.534z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M84.60533 451.71752L92.73168 451.71672 96.59743 420.3719 84.60368 420.37272 84.60533 451.71752z"
      ></path>
      <path
        fill="#2f2e41"
        d="M112.447 451.533c.253.426.387 1.802.387 2.297a2.758 2.758 0 01-2.758 2.758H84.909a1.882 1.882 0 01-1.881-1.881v-1.048s-1.245-3.149 1.318-7.03c0 0 3.186 3.039 7.946-1.721l1.403-2.543 10.162 7.431 5.632.693c1.232.152 2.324-.023 2.958 1.044z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M46.48117 451.71752L54.60752 451.71672 58.47327 420.3719 46.47952 420.37272 46.48117 451.71752z"
      ></path>
      <path
        fill="#2f2e41"
        d="M74.323 451.533c.253.426.387 1.802.387 2.297a2.758 2.758 0 01-2.759 2.758H46.785a1.882 1.882 0 01-1.881-1.881v-1.048s-1.245-3.149 1.318-7.03c0 0 3.185 3.039 7.946-1.721l1.403-2.543 10.161 7.431 5.633.693c1.232.152 2.324-.023 2.958 1.044z"
      ></path>
      <path
        fill="#2f2e41"
        d="M34.97848 266.77741L31.55506 287.6983 42.2057 355.40594 44.86836 437.18763 59.24792 437.18763 66.55002 359.9705 65.78926 308.99958 82.90636 437.94839 97.849 437.94839 99.26269 276.28691 34.97848 266.77741z"
      ></path>
      <path
        fill={theme.accentColor}
        d="M52.856 158.37h26.92l23.29 13.693c-3.624-.922-6.425 32.708-6.52 62.423-.006 1.974-1.963 4.244-1.945 6.173.016 1.662 2.003 2.99 2.037 4.601.033 1.507.073 2.989.123 4.44.04 1.204-2.213 2.8-2.16 3.959.068 1.48 2.447 2.509 2.535 3.902.543 8.566 1.479 15.352 2.887 18.726 0 0-60.1 16.737-64.664-9.13 0 0 .26-9.296.224-22.135-.003-1.139-.973-2.172-.982-3.363-.006-.885.95-1.918.942-2.828-.008-.748-.932-1.41-.942-2.172-.006-.455.903-1.007.896-1.466-.365-24.414-2.004-55.18-7.746-63.13l25.105-13.694z"
      ></path>
      <path
        fill={theme.accentColor}
        d="M79.483 158.37s17.444 6.338 22.979 11.388c2.941 2.684 22.667 53.276 22.667 53.276l27.397 47.714-14.241 5.41-29.132-43.995-12.933-28.909-16.737-44.885z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M43.301 281.8c2.545 4.33 2.373 9.154-.383 10.773-2.756 1.62-7.053-.58-9.597-4.91-1.114-1.896-1.707-3.886-1.79-5.66L20.98 263.516l8.78-4.766 9.469 18.73c1.51.937 2.959 2.424 4.072 4.32z"
      ></path>
      <path
        fill={theme.accentColor}
        d="M49.053 181.953l-7.608-17.498s-17.878 5.706-17.878 14.074c0 0-10.65 63.904-9.129 79.88l9.89 17.498 14.074-11.031-8.749-20.16 19.4-62.763z"
      ></path>
      <path
        fill="#2f2e41"
        d="M33.40674 295.62938L42.38042 277.80843 52.85634 292.26286 43.42802 301.39198 33.40674 295.62938z"
      ></path>
      <circle cx="66.063" cy="135.932" r="17.411" fill="#ffb6b6"></circle>
      <path
        fill="#2f2e41"
        d="M79.64 116.483c-3.026-.97-4.458-4.568-7.226-6.128-3.06-1.725-6.722-2.802-10.097-1.826-2.07.599-3.875 1.922-5.947 2.516a6.278 6.278 0 01-1.13.213c-2.23.212-4.143 1.549-5.256 3.49l-.03.05c-.653 1.12-1.583 2.046-2.408 3.046-3.01 3.648-4.639 8.388-4.605 13.117.036 5.084 1.992 10.13 5.39 13.91l.869-.4 6.295 5.438c-1.142-4.12-2.295-8.385-1.69-12.618.605-4.233 3.451-8.456 7.648-9.273 1.588-.309 3.262-.122 4.803-.613 1.542-.49 2.961-2.075 2.432-3.604.955 2.523 4.514 2.374 6.964 3.503 5.484 2.526 3.476-1.378 8.236 2.336 1.608-8.911 7.767-9.309-4.249-13.157z"
      ></path>
      <path
        fill="#ccc"
        d="M0 455.986c0 .66.53 1.19 1.19 1.19h403.29c.66 0 1.19-.53 1.19-1.19 0-.66-.53-1.19-1.19-1.19H1.19c-.66 0-1.19.53-1.19 1.19z"
      ></path>
      <path
        fill="#3f3d56"
        d="M351.722 457.176H175.297c-21.512 0-39.013-17.501-39.013-39.014V39.013C136.284 17.501 153.785 0 175.297 0h176.425c21.511 0 39.013 17.501 39.013 39.013v379.15c0 21.512-17.502 39.013-39.013 39.013z"
      ></path>
      <path
        fill="#3f3d56"
        d="M390.735 139.464c-.91 0-1.65.74-1.65 1.65v52.814c0 .91.74 1.65 1.65 1.65s1.65-.74 1.65-1.65v-52.814c0-.91-.74-1.65-1.65-1.65z"
      ></path>
      <path
        fill="#fff"
        d="M387.022 39.014v379.142c0 18.724-14.574 34.04-33.001 35.229h-.009c-.272.016-.544.033-.825.041-.487.025-.974.033-1.469.033 0 0-1.815-.132-5.133-.38-3.35-.247-8.227-.618-14.31-1.105-1.823-.14-3.746-.297-5.776-.462-4.043-.33-8.483-.694-13.245-1.106-1.898-.157-3.862-.33-5.867-.504a3229.297 3229.297 0 01-33.834-3.086 973.49 973.49 0 01-6.19-.594c-.544-.058-1.097-.107-1.65-.165-8.632-.85-125.716-23.478-125.716-27.901V39.014c0-19.5 15.804-35.304 35.304-35.304h21.076c3.367 0 6.074 2.625 6.363 5.983.024.223.049.446.09.669.61 3.375 3.706 5.727 7.138 5.727h107.083c3.432 0 6.527-2.352 7.138-5.727.04-.223.066-.446.09-.669.29-3.358 2.996-5.983 6.363-5.983h21.076c19.5 0 35.303 15.804 35.303 35.304z"
      ></path>
      <path
        fill="#3f3d56"
        d="M135.459 99.853c-.91 0-1.65.74-1.65 1.65v13.204c0 .91.74 1.65 1.65 1.65s1.65-.74 1.65-1.65v-13.204c0-.91-.74-1.65-1.65-1.65zM135.459 139.464c-.91 0-1.65.74-1.65 1.65v25.582c0 .91.74 1.65 1.65 1.65s1.65-.74 1.65-1.65v-25.582c0-.91-.74-1.65-1.65-1.65zM135.459 175.773c-.91 0-1.65.74-1.65 1.65v25.583c0 .91.74 1.65 1.65 1.65s1.65-.74 1.65-1.65v-25.582c0-.91-.74-1.65-1.65-1.65z"
      ></path>
      <rect
        width="32.184"
        height="4.126"
        x="163.929"
        y="9.49"
        fill="#e6e6e6"
        rx="0.31"
        ry="0.31"
      ></rect>
      <circle cx="335.857" cy="10.315" r="3.301" fill="#e6e6e6"></circle>
      <circle cx="344.935" cy="10.315" r="3.301" fill="#e6e6e6"></circle>
      <circle cx="354.012" cy="10.315" r="3.301" fill="#e6e6e6"></circle>
      <path
        fill="#e6e6e6"
        d="M333.51 95.159h-140c-2.206 0-4-1.794-4-4s1.794-4 4-4h140c2.205 0 4 1.794 4 4s-1.795 4-4 4zM357.05 411.659h-52c-2.206 0-4-1.794-4-4s1.794-4 4-4h52c2.205 0 4 1.794 4 4s-1.795 4-4 4zM218.05 411.659h-52c-2.206 0-4-1.794-4-4s1.794-4 4-4h52c2.205 0 4 1.794 4 4s-1.795 4-4 4z"
      ></path>
      <path
        fill="#3f3d56"
        d="M165.969 105.907H361.04999999999995V106.907H165.969z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M333.51 156.159h-140c-2.206 0-4-1.794-4-4s1.794-4 4-4h140c2.205 0 4 1.794 4 4s-1.795 4-4 4z"
      ></path>
      <path
        fill="#3f3d56"
        d="M165.969 166.907H361.04999999999995V167.907H165.969z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M333.51 217.159h-140c-2.206 0-4-1.794-4-4s1.794-4 4-4h140c2.205 0 4 1.794 4 4s-1.795 4-4 4z"
      ></path>
      <path
        fill="#3f3d56"
        d="M165.969 227.907H361.04999999999995V228.907H165.969z"
      ></path>
      <path
        fill="#3f3d56"
        d="M165.969 279.907H361.04999999999995V280.907H165.969z"
      ></path>
      <path
        fill="#3f3d56"
        d="M165.969 331.907H361.04999999999995V332.907H165.969z"
      ></path>
      <circle cx="264.882" cy="310.486" r="58" fill={theme.accentColor}></circle>
    </svg>
  );
}
