import React from "react";

export default function EmailImg(props) {
  const theme = props.theme;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      version="1.1"
      viewBox="0 0 300 300"
    >
      <g transform="translate(-231.54 -375.82)">
        <ellipse
          cx="381.54"
          cy="525.82"
          fill={theme.iconsBg}
          color={theme.iconsBg}
          rx="143.458"
          ry="143.45"
        ></ellipse>
        <g
          fill={theme.primaryColor}
          transform="matrix(3.81893 0 0 3.81893 -1398.14 -1597.456)"
        >
          <g fill={theme.primaryColor} transform="translate(-560.19 271.89)">
            <path
              style={{
                lineHeight: "normal",
                WebkitTextIndent: "0",
                textIndent: "0",
                WebkitTextAlign: "start",
                textAlign: "start",
                WebkitTextDecorationLine: "none",
                textDecorationLine: "none",
                WebkitTextDecorationStyle: "solid",
                textDecorationStyle: "solid",
                WebkitTextDecorationColor: "#000000",
                textDecorationColor: "#000000",
                WebkitTextTransform: "none",
                textTransform: "none",
                blockProgression: "tb",
                isolation: "auto",
              }}
              fill={theme.primaryColor}
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="4"
              strokeOpacity="1"
              strokeWidth="2.952"
              d="M1005.12 267.465c-1.03 0-1.874.841-1.874 1.87v29.52a1.88 1.88 0 001.873 1.875h42.172a1.88 1.88 0 001.873-1.875v-29.52c0-1.029-.844-1.87-1.873-1.87h-42.172zm4.991 4.484h32.176l-16.086 14.055-16.09-14.055zm35.307 2.238v20.764l-11.764-10.486 11.764-10.278zm-38.426.012l11.813 10.322-11.813 10.532v-20.854zm23.82 12.748l11.192 9.979h-31.482l11.126-9.92 3.317 2.898a1.88 1.88 0 002.465 0l3.383-2.957z"
              baselineShift="baseline"
              clipRule="nonzero"
              color="#000"
              colorInterpolation="sRGB"
              colorInterpolationFilters="linearRGB"
              colorRendering="auto"
              direction="ltr"
              display="inline"
              enableBackground="accumulate"
              fontFamily="sans-serif"
              fontSize="medium"
              fontStretch="normal"
              fontStyle="normal"
              fontVariant="normal"
              fontWeight="normal"
              imageRendering="auto"
              letterSpacing="normal"
              opacity="1"
              overflow="visible"
              shapeRendering="auto"
              textAnchor="start"
              textDecoration="none"
              textRendering="auto"
              visibility="visible"
              wordSpacing="normal"
              writingMode="lr-tb"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
