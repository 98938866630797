import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import PortfolioCard from "../../components/portolioCard/PortfolioCard";
import { Fade } from "react-reveal";
import { portfolioHeader, portfolioSites, portfolioSitesDexs } from "../../portfolio.js";
import "./Projects.css";
import PortfolioImg from "./PortfolioImg";
import FloatButton from '../../components/floatingButton/FloatButton'
import { style } from "glamor";

function Portfolio(props) {
  const theme = props.theme;

  const styles = style({
    backgroundColor: `${theme.accentBright}`,
    ":hover": {
      boxShadow: `0 5px 15px ${theme.accentBright}`,
    },
  });

  return (
    <div className="projects-main">
      <Header theme={theme} setTheme={props.setTheme} />
      <div className="basic-projects">
        <Fade bottom duration={2000} distance="40px">
          <div className="projects-heading-div">
            <div className="projects-heading-img-div">
              <PortfolioImg theme={theme} />
            </div>
            <div className="projects-heading-text-div">
              <h1
                className="projects-heading-text"
                style={{ color: theme.secondaryText }}
              >
                {portfolioHeader.title}
              </h1>
              <p
                className="projects-header-detail-text subTitle"
                style={{ color: theme.text }}
              >
                {portfolioHeader["description"]}
              </p>
            </div>
          </div>
        </Fade>
      </div>
      <Fade bottom duration={2000} distance="70px">
        <h1 className="certs-header" style={{ color: theme.text }}>
          Websites
        </h1>
      </Fade>
      <div className="repo-cards-div-main">
        {portfolioSites.data.map((address) => {
          return (
            <PortfolioCard addresso={address} key={address.id} theme={theme} />
          );
        })}
      </div>

      <br />
      <br />
      <br />
      <Fade bottom duration={2000} distance="70px">
        <h1 className="certs-header" style={{ color: theme.text }}>
          DEXs & Others
        </h1>
      </Fade>
      <div className="repo-cards-div-main">
        {portfolioSitesDexs.data.map((addresso) => {
          return (
            <PortfolioCard
              addresso={addresso}
              key={addresso.id}
              theme={theme}
            />
          );
        })}
      </div>
      <br />
      <br />
      <br />
      <a
        {...styles}
        style={{ backgroundColor: props.theme.primaryColor }}
        className="general-btn"
        href="https://github.com/ainzics"
      >
        More Projects (Github)
      </a>
      <br />
      <br />
      <Footer theme={props.theme} onToggle={props.onToggle} />
      <FloatButton theme={props.theme} />
    </div>
  );
}

export default Portfolio;
