import React from "react";
import "./Greeting.css";
// import SocialMedia from "../../components/socialMedia/SocialMedia";
import { greeting } from "../../portfolio";
import { Fade } from "react-reveal";
// import { useHistory } from "react-router-dom";
import FeelingProud from "./FeelingProud";
import FeelingProudLight from "./FeelingProudLight";
// import { style } from "glamor";

export default function Greeting(props) {
  const theme = props.theme;
  // const history = useHistory();

  // const styles = style({
  //   backgroundColor: `${theme.accentBright}`,
  //   ":hover": {
  //     boxShadow: `0 5px 15px ${theme.accentBright}`,
  //   },
  // });


  const ThemeImage =
    props.theme.name === "dark" ? (
      <FeelingProud theme={props.theme} />
    ) : (
      <FeelingProudLight theme={props.theme} />
    );

  return (
    <Fade bottom duration={2000} distance="40px">
      {/* <div className="hint"> */}
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1
                className="greeting-text"
                style={{ color: theme.greetColor }}
              >
                {greeting.title}
              </h1>
              <p
                className="greeting-text-p subTitle"
                style={{ color: theme.text }}
              >
                {/* <span style={{ color: theme.accentColor }}>
                  {greeting.full_name}.{" "}
                </span> */}
                {greeting.subTitle}
                {/* <br /> */}
                {/* <br /> */}
                {/* <span style={{ color: theme.accentColor }}>
                    {greeting.full_name}.{" "}
                  </span>
                  {greeting.secondsubTitle} */}
                <br />
                <br />
                <span style={{ color: theme.greetColor }}>
                  {greeting.full_name}.{" "}
                </span>
                {greeting.thirdsubTitle}
              </p>
              {/* <SocialMedia /> */}
              <div className="portfolio-repo-btn-div">
                {/* <button
                    {...styles}
                    className="button"
                    onClick={() => {
                      history.push("/contact");
                    }}
                  >
                    Contact Us
                  </button> */}
              </div>
            </div>
          </div>
          <div className="greeting-image-div">{ThemeImage}</div>
        </div>
      </div>
      {/* </div> */}
    </Fade>
  );
}
