import React from "react";
import "./SpecialityCard.css";
import { Fade } from "react-reveal";

const SpecialityCard = (props) => {
    const theme = props.theme
    const special = props.specs;
  return (
    <Fade left duration={2000}>
      {/* <div className="box-container BoxixBorder"> */}
      <div className="box-item">
        <div className="flip-box">
          <div
            className="flip-box-front text-center"
            style={{ backgroundColor: theme.spcCardFr }}
          >
            <div className="inner color-white">
              <h3 className="flip-box-header">{special.title}</h3>
              <p>{special.subtitle}</p>
              {/* <p>{special.rating}</p> */}
              {/* <img
                src="https://s25.postimg.cc/65hsttv9b/cta-arrow.png"
                alt=""
                className="flip-box-img"
              /> */}
            </div>
          </div>
          <div
            className="flip-box-back text-center"
            style={{ backgroundColor: theme.spcCardBg }}
          >
            <div className="inner color-white">
              {/* <h3 className="flip-box-header">Custom Domains</h3> */}
              <p>{special.discription}</p>
              <button className="flip-box-button">Let's try</button>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </Fade>
  );
};

export default SpecialityCard;
