import React from "react";
import "./SpecialitiesCards.css";
import { Fade } from "react-reveal";
import { ourSpecialities } from "../../portfolio";
import SpecialityCard from "../../components/specialityCard/SpecialityCard";

function SpecialitiesCards(props) {
  const theme = props.theme;
  return (
    <div className="main" id="certs">
      <div className="special-header-div">
        <Fade bottom duration={2000} distance="20px">
          <h1 className="special-header" style={{ color: theme.secondaryText }}>
            Why AINZICS?
          </h1>
        </Fade>
      </div>
      <div className="special-body-div">
        {ourSpecialities.ourSpeciality.map((prof) => {
          return <SpecialityCard specs={prof} key={prof.id} theme={theme} />;
        })}
      </div>
    </div>
  );
}

export default SpecialitiesCards;
