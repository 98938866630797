import React from "react";

export default function TelegramImg(props) {
  const theme = props.theme;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill={theme.iconsBg}></circle>
      <path
        fill={theme.primaryColor}
        d="M29.665 12.336a.416.416 0 00-.283-.308 1.455 1.455 0 00-.764.054s-17.004 6.112-17.975 6.789c-.209.146-.279.23-.314.33-.168.485.355.694.355.694l4.383 1.428c.074.013.15.008.222-.013.996-.63 10.027-6.335 10.552-6.527.08-.024.143 0 .127.061-.209.732-8.013 7.664-8.056 7.706a.162.162 0 00-.058.143l-.41 4.28s-.17 1.331 1.161 0a37.321 37.321 0 012.304-2.11c1.507 1.041 3.129 2.192 3.828 2.794a1.219 1.219 0 00.89.342 1 1 0 00.85-.754s3.096-12.471 3.2-14.142c.01-.162.024-.268.026-.38a1.42 1.42 0 00-.038-.387z"
      ></path>
    </svg>
  );
}
