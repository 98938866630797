import React from "react";
import { PopupButton } from "react-calendly";
import "./Whatsapp.css";
// This is the way to import an SVG file and then pass it as a props
// import { ReactComponent as CompanyIcon } from "../../assests/images/ainzics.svg";

// import { WhatsAppWidget } from "react-whatsapp-widget";
// import "react-whatsapp-widget/dist/index.css";

const Whatsapp = (props) => {
  const theme = props.theme;
  const paddingValue = props.padding;
  // const [state, setState] = useState(true);
  // const [branding, setBranding] = useState(null);
  // useEffect(() => {
  //   setBranding(document.querySelector('div[data-id="branding"]'));
  //   if (state && branding !== null) {
  //     branding.style.display = "none";
  //   }
  //   return () => setState(false);
  // }, [branding]);

  return (
    <div className="meeting-button-div">
      <PopupButton
        url="https://calendly.com/ainzics/project-discussion"
        className="meeting-button"
        styles={{ padding: paddingValue }}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
        text="Schedule Meeting!"
        textColor="red"
        color="#00a2ff"
        pageSettings={{
          backgroundColor: theme.body,
          primaryColor: "#01ec52",
          textColor: theme.text,
          hideGdprBanner: true,
          hideEventTypeDetails: false,
          hideLandingPageDetails: true,
        }}
      />
    </div>
  );
};

export default Whatsapp;
