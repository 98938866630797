// Theme Presets

const lightTheme = {
  name: "light",
  body: "#FFFFFF",
  text: "#343434",
  dark: "#000000",
  primaryColor: "#8b00f7",
  secondaryText: "#7F8DAA",
  greetColor: "#00ff9e",
  discriptionText: "#000000",
  ratingText: "#7F8DAA",
  projectsText: "#e06d1b",
  accentColor: "#19ed95",
  dullbgColor: "#30046f",
  // accentColor: "#E3405F",
  accentBright: "#2c611f",
  projectCard: "#DCE4F2",
  skinColor: "#F7B799",
  skinColor2: "#FCB696",
  imageDark: "#dce4f2",
  imageClothes: "#dce4f2",
  avatarMisc: "#e9ecf2",
  avatarShoes: "#ccd2e3",
  langColor: "#000000",
  inputColor: "#809bce",
  spcCardBg: "#809bce",
  spcCardFr: "#95b8d1",
  teamCardBg: "#95b8d1",
  iconsBg: "#95b8d1",
  lightText: "#FFFFFF",
};

const darkTheme = {
  name: "dark",
  body: "#171941",
  text: "#ffffff",
  dark: "#000000",
  primaryColor: "#5a0bb3",
  secondaryText: "#e5bcff",
  greetColor: "#00ff9e",
  discriptionText: "#FFFFFF",
  projectsText: "#e06d1b",
  accentColor: "#5a0bb3",
  dullbgColor: "#30046f",
  accentBright: "#2c611f",
  projectCard: "#3c0a66",
  skinColor: "#F7B799",
  skinColor2: "#FCB696",
  imageDark: "#292A2D",
  imageClothes: "#000000",
  avatarMisc: "#212121",
  avatarShoes: "#2B2B2B",
  langColor: "#000000",
  inputColor: "#5f40cb",
  spcCardBg: "#3e2d84",
  spcCardFr: "#3a2f67",
  teamCardBg: "#3a2f67",
  iconsBg: "#ffffff",
  lightText: "#FFFFFF",
};

export const themes = { light: lightTheme, dark: darkTheme };
