import React from "react";

export default function FacebookImg(props) {
  const theme = props.theme;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="18" r="18" fill={theme.primaryColor}></circle>
      <path
        fill={theme.iconsBg}
        fillRule="evenodd"
        stroke="null"
        d="M17.232 30.87h4.473V19.999h2.984l.396-3.746h-3.38l.005-1.876c0-.976.093-1.5 1.488-1.5h1.865V9.13H22.08c-3.586 0-4.847 1.818-4.847 4.874v2.249h-2.235v3.746h2.235v10.87zm2.809 9.111C9.05 39.981.14 31.035.14 20 .142 8.964 9.052.019 20.042.019S39.94 8.964 39.94 20c0 11.035-8.91 19.981-19.9 19.981z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
