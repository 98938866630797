/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
  googleTrackingID: "",
};

//Home Page
const greeting = {
  title: "AINZICS",
  title2: "AINZICS",
  logo_path_light: "logodark.png",
  logo_path_dark: "logo.png",
  nickname: "Inzii / picleric",
  full_name: "AINZICS",
  subTitle:
    " is a Web Development Company which especially deals in latest innovative Services and Solutions.🔥",
  secondsubTitle:
    " is dealing in several emerging technologies. We have a vision that strives to bring powerful and revolutionary aspects of latest technologies to build applications to meet your varied business needs.",
  thirdsubTitle:
    " web development company is a team of experienced web developers, designers, and programmers who can create and maintain a website for your business. AINZICS is creating custom web solutions based on your individual needs and goals. We can develop a website from scratch, or we can also provide ongoing maintenance and updates.",
  resumeLink:
    "https://drive.google.com/file/d/1-RZcGQEiwnA4DNSTOxH7wrL1l9mfGDA4/view?usp=sharing",
  mail: "mailto:ainzics@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/ainzics",
  linkedin: "https://www.linkedin.com/in/ainzics/",
  gmail: "ainzics@gmail.com",
  gitlab: "https://gitlab.com/ainzics",
  facebook: "https://www.facebook.com/ainzics/",
  twitter: "https://twitter.com/ainzics",
  instagram: "https://www.instagram.com/ainzics/",
};

const skills = {
  data: [
    {
      id: "1",
      title: "BlockChain Development",
      fileName: "BlockchainImg",
      skills: [
        "⚡ Decentralized Exchanges (DEXs) for EVM BlockChain NetWorks.",
        "⚡ NFT Marketplace with Attractive Designes and  Automated Features.",
        "⚡ ERC-20, BEP-20, TRC-20 Tokens and Token Generator.",
        "⚡ Responsive website front-end using ReactJS & Typescript",
        "⚡ Mobile applications using React Native",
        "⚡ Application backend in Node, Express, & Solidity.",
        "⚡ Integration of Smart-Contracts with Front-end using Web3.",
        "⚡ Integration of third party services like Firebase, AWS, Digital-Ocean, etc.",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        {
          skillName: "Android",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "Ethereum",
          fontAwesomeClassname: "simple-icons:ethereum",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "Bitcoin",
          fontAwesomeClassname: "simple-icons:bitcoin",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "Binance Smart Chain",
          fontAwesomeClassname: "simple-icons:binance",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "Solidity",
          fontAwesomeClassname: "simple-icons:solidity",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "GraphQL",
          fontAwesomeClassname: "simple-icons:graphql",
          style: {
            color: "#E94E32",
          },
        },
      ],
    },
    {
      id: "2",
      title: "Website & Web Application Development",
      fileName: "WebappImg",
      skills: [
        "⚡ Customized Web-app Development.",
        "⚡ Website Development for Business Needs.",
        "⚡ Complete Web-App with front-end and Back-end.",
        "⚡ Full Responsive and SEO Friendly Websites.",
        "⚡ Highly interactive Front-end & UI for web and Mobile applications.",
        "⚡ Integration of third party services like Firebase, AWS, Digital-Ocean, etc.",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        {
          skillName: "Android",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        // {
        //   skillName: "Java",
        //   fontAwesomeClassname: "simple-icons:java",
        //   style: {
        //     color: "#f89820",
        //   },
        // },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "Dart",
          fontAwesomeClassname: "simple-icons:dart",
          style: {
            color: "#29B0EE",
          },
        },
        // {
        //   skillName: "jQuery",
        //   fontAwesomeClassname: "simple-icons:jquery",
        //   style: {
        //     color: "#0865A6",
        //   },
        // },
        {
          skillName: "Wordpress",
          fontAwesomeClassname: "simple-icons:wordpress",
          style: {
            color: "#207297",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        // {
        //   skillName: "C",
        //   fontAwesomeClassname: "simple-icons:c",
        //   style: {
        //     color: "#E94E32",
        //   },
        // },
        // {
        //   skillName: "C++",
        //   fontAwesomeClassname: "simple-icons:cplusplus",
        //   style: {
        //     color: "#E94E32",
        //   },
        // },
        // {
        //   skillName: "Python",
        //   fontAwesomeClassname: "simple-icons:python",
        //   style: {
        //     color: "#3776AB",
        //   },
        // },
      ],
    },
    {
      id: "3",
      title: "Mobile Application Development",
      fileName: "MobileappImg",
      skills: [
        "⚡ Customized Mobile-app Development.",
        "⚡ Web-Application Development for Business Needs.",
        "⚡ Complete Mobile-App with front-end and Back-end.",
        "⚡ Full Optimized and SEO Friendly Web-App.",
        "⚡ Highly interactive Front-end & UI for Mobile applications.",
        "⚡ Integration of third party services like Firebase, AWS, Digital-Ocean, etc.",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        // {
        //   skillName: "PHP",
        //   fontAwesomeClassname: "simple-icons:php",
        //   style: {
        //     color: "#7377AD",
        //   },
        // },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        // {
        //   skillName: "GraphQL",
        //   fontAwesomeClassname: "simple-icons:graphql",
        //   style: {
        //     color: "#DE33A6",
        //   },
        // },
        {
          skillName: "Android",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        // {
        //   skillName: "Java",
        //   fontAwesomeClassname: "simple-icons:java",
        //   style: {
        //     color: "#f89820",
        //   },
        // },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "Dart",
          fontAwesomeClassname: "simple-icons:dart",
          style: {
            color: "#29B0EE",
          },
        },
        // {
        //   skillName: "Visual Basic",
        //   fontAwesomeClassname: "simple-icons:dot-net",
        //   style: {
        //     color: "#029FCE",
        //   },
        // },
        // {
        //   skillName: "MySQL",
        //   fontAwesomeClassname: "simple-icons:mysql",
        //   style: {
        //     color: "#4479A1",
        //   },
        // },
        // {
        //   skillName: "jQuery",
        //   fontAwesomeClassname: "simple-icons:jquery",
        //   style: {
        //     color: "#0865A6",
        //   },
        // },
        // {
        //   skillName: "Wordpress",
        //   fontAwesomeClassname: "simple-icons:wordpress",
        //   style: {
        //     color: "#207297",
        //   },
        // },
        // {
        //   skillName: "Apache",
        //   fontAwesomeClassname: "simple-icons:apache",
        //   style: {
        //     color: "#CA1A22",
        //   },
        // },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        // {
        //   skillName: "C",
        //   fontAwesomeClassname: "simple-icons:c",
        //   style: {
        //     color: "#E94E32",
        //   },
        // },
        // {
        //   skillName: "C++",
        //   fontAwesomeClassname: "simple-icons:cplusplus",
        //   style: {
        //     color: "#E94E32",
        //   },
        // },
        // {
        //   skillName: "Python",
        //   fontAwesomeClassname: "simple-icons:python",
        //   style: {
        //     color: "#3776AB",
        //   },
        // },
      ],
    },
    {
      id: "4",
      title: "E-Commerce Development",
      fileName: "CommerceImg",
      skills: [
        "⚡ e-Commerce Development Complete ",
        "⚡ Payment Method Gateways integration ",
        "⚡ Shopify Store with Complete Functionality ",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        // {
        //   skillName: "PHP",
        //   fontAwesomeClassname: "simple-icons:php",
        //   style: {
        //     color: "#7377AD",
        //   },
        // },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        // {
        //   skillName: "GraphQL",
        //   fontAwesomeClassname: "simple-icons:graphql",
        //   style: {
        //     color: "#DE33A6",
        //   },
        // },
        {
          skillName: "Android",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        // {
        //   skillName: "Java",
        //   fontAwesomeClassname: "simple-icons:java",
        //   style: {
        //     color: "#f89820",
        //   },
        // },
        // {
        //   skillName: "Flutter",
        //   fontAwesomeClassname: "simple-icons:flutter",
        //   style: {
        //     color: "#02569B",
        //   },
        // },
        // {
        //   skillName: "Dart",
        //   fontAwesomeClassname: "simple-icons:dart",
        //   style: {
        //     color: "#29B0EE",
        //   },
        // },
        // {
        //   skillName: "Visual Basic",
        //   fontAwesomeClassname: "simple-icons:dot-net",
        //   style: {
        //     color: "#029FCE",
        //   },
        // },
        // {
        //   skillName: "MySQL",
        //   fontAwesomeClassname: "simple-icons:mysql",
        //   style: {
        //     color: "#4479A1",
        //   },
        // },
        // {
        //   skillName: "jQuery",
        //   fontAwesomeClassname: "simple-icons:jquery",
        //   style: {
        //     color: "#0865A6",
        //   },
        // },
        {
          skillName: "Wordpress",
          fontAwesomeClassname: "simple-icons:wordpress",
          style: {
            color: "#207297",
          },
        },
        // {
        //   skillName: "Apache",
        //   fontAwesomeClassname: "simple-icons:apache",
        //   style: {
        //     color: "#CA1A22",
        //   },
        // },
        // {
        //   skillName: "Git",
        //   fontAwesomeClassname: "simple-icons:git",
        //   style: {
        //     color: "#E94E32",
        //   },
        // },
        // {
        //   skillName: "C",
        //   fontAwesomeClassname: "simple-icons:c",
        //   style: {
        //     color: "#E94E32",
        //   },
        // },
        // {
        //   skillName: "C++",
        //   fontAwesomeClassname: "simple-icons:cplusplus",
        //   style: {
        //     color: "#E94E32",
        //   },
        // },
        // {
        //   skillName: "Python",
        //   fontAwesomeClassname: "simple-icons:python",
        //   style: {
        //     color: "#3776AB",
        //   },
        // },
      ],
    },
    {
      id: "5",
      title: "Server Deployment",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud and Cpanel platforms",
        "⚡ Expertise in hosting and managing websites on Cpanel and Cloud.",
        "⚡ Experience with Continuous Integration",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "simple-icons:netlify",
          style: {
            color: "#38AFBB",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        // {
        //   skillName: "PostgreSQL",
        //   fontAwesomeClassname: "simple-icons:postgresql",
        //   style: {
        //     color: "#336791",
        //   },
        // },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "GitHub Actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#5b77ef",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Virtual University Of Pakistan",
      subtitle: "BS-Computer Science",
      logo_path: "virtual.png",
      alt_name: "virtual University Of Pakistan",
      duration: "2017 - 2021",
      descriptions: [
        "⚡ I have studied core subjects like Introduction to Programming, Data Structures, DBMS, Networking, Security, etc.",
        "⚡ I have also completed various online courses for Front-End , Web , Mobile App Development, etc.",
        "⚡ I have implemented several projects based on what I've learnt under my Computer Science courses. ",
      ],
      website_link: "http://vu.edu.pk/",
    },
    {
      title: "Govt. Municipal Degree College Faisalabad",
      subtitle: "FSc. Pre-Engineering",
      logo_path: "gmdc.png",
      alt_name: "degreecollege",
      duration: "2014 - 2016",
      descriptions: [
        "⚡ I have studied subjects like Math, Physics, and Chemistry etc.",
        "⚡ I have studied some other online courses and learnt about programming basics.",
        "⚡ Also learnt about the Basics of Blogging, Digital Marketing, SEO, and Content Writing.",
      ],
      website_link: "http://www.gmdc.edu.pk/",
    },
    {
      title: "Ghazali Educational Complex Faisalabad",
      subtitle: "Matric",
      logo_path: "ghazali.png",
      alt_name: "ghazali",
      duration: "2012 - 2014",
      descriptions: [
        "⚡ I completed my Matriculation from the Ghazali Educational Complex Faisalabad",
        "⚡ I have studied Science Subjects like Physics, Biology, Math, and Chemistry.",
        // "⚡ I have also completed various online courses for Backend , Web , Mobile App Development, etc.",
        // "⚡ I have implemented several projects based on what I've leart under my Computer Engineering course. ",
      ],
      website_link: "http://www.ghazalian.pk/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "FRL101: Freelancing Basics",
      subtitle: "DigiSkill (Ignite)",
      logo_path: "digiskills.webp",
      certificate_link: "https://drive.google.com",
      alt_name: "DigiSkills",
      // color_code: "#2AAFED",
      color_code: "#243c71",
    },
    {
      title: "Digital Marketing",
      subtitle: "DigiSkill (Ignite)",
      logo_path: "digiskills.webp",
      certificate_link: "https://drive.google.com",
      alt_name: "DigiSkills",
      color_code: "#243c71",
    },
    // color_code: "#8C151599",
    // color_code: "#7A7A7A",
    // color_code: "#0C9D5899",
    // color_code: "#C5E2EE",
    // color_code: "#ffc475",
    // color_code: "#g",
    // color_code: "#ffbfae",
    // color_code: "#fffbf3",
    // color_code: "#b190b0",
  ],
};
const myprofiles = {
  myprofiles: [
    {
      title: "Upwork",
      subtitle: "Muhammad Inzamam Shakir",
      discription: "BlockChain Developer | DEXs Expert",
      rating: "⭐⭐⭐⭐⭐",
      projects: "Projects In Progress (4)",
      logo_path: "upwork.png",
      certificate_link:
        "https://www.upwork.com/freelancers/~01a923268a7790228f",
      alt_name: "upwork",
      color_code: "#14a800",
    },
    {
      title: "Fiverr",
      subtitle: "DigiDreamers",
      discription: "Blockchain Developer | DEXs Expert",
      rating: "⭐⭐⭐⭐⭐",
      projects: "Projects In Progress (3)",
      logo_path: "fiverr.png",
      certificate_link: "https://www.fiverr.com/digidreamers",
      alt_name: "Fiverr",
      color_code: "#1dbf73",
    },
    {
      title: "Freelancer",
      subtitle: "Muhammad Inzamam Shakir",
      discription: "Blockchain Developer | DEXs Expert",
      rating: "⭐⭐⭐⭐⭐",
      projects: "Projects In Progress (2)",
      logo_path: "freelancer.png",
      certificate_link: "https://www.freelancer.com/u/MianInzamam",
      alt_name: "Freelancer",
      color_code: "#007fed",
    },
    {
      title: "Kwork",
      subtitle: "Muhammad Inzamam Shakir",
      discription: "Blockchain Developer | DEXs Expert",
      rating: "⭐⭐⭐⭐⭐",
      projects: "Projects In Progress (1)",
      logo_path: "kwork.png",
      certificate_link: "https://kwork.com/user/m_inzamam_shakir",
      alt_name: "Kwork",
      color_code: "#333333",
    },
    {
      title: "Revolancer",
      subtitle: "Muhammad Inzamam Shakir",
      discription: "Blockchain Developer | DEXs Expert",
      rating: "⭐⭐⭐⭐⭐",
      projects: "Projects In Progress (2)",
      logo_path: "revo.png",
      certificate_link: "https://www.freelancer.com/u/MianInzamam",
      alt_name: "Freelancer",
      color_code: "#0d253a",
    },
    // color_code: "#8C151599",
    // color_code: "#7A7A7A",
    // color_code: "#0C9D5899",
    // color_code: "#C5E2EE",
    // color_code: "#ffc475",
    // color_code: "#g",
    // color_code: "#ffbfae",
    // color_code: "#fffbf3",
    // color_code: "#b190b0",
  ],
};
const ourServices = {
  ourServices: [
    {
      id: "1",
      title: "Blockchain Development",
      subtitle: "Muhammad Inzamam Shakir",
      discription: "BlockChain Developer | DEXs Expert",
      rating: "⭐⭐⭐⭐⭐",
      projects: "Projects In Progress (4)",
      logo_path: "upwork.png",
      certificate_link:
        "https://www.upwork.com/freelancers/~01a923268a7790228f",
      alt_name: "upwork",
      // color_code: "#2AAFED",
      color_code: "#14a800",
    },
    {
      id: "2",
      title: "Fiverr",
      subtitle: "DigiDreamers",
      discription: "Blockchain Developer | DEXs Expert",
      rating: "⭐⭐⭐⭐⭐",
      projects: "Projects In Progress (3)",
      logo_path: "fiverr.png",
      certificate_link: "https://www.fiverr.com/digidreamers",
      alt_name: "Fiverr",
      color_code: "#1dbf73",
    },
    {
      id: "3",
      title: "Freelancer",
      subtitle: "Muhammad Inzamam Shakir",
      discription: "Blockchain Developer | DEXs Expert",
      rating: "⭐⭐⭐⭐⭐",
      projects: "Projects In Progress (2)",
      logo_path: "freelancer.png",
      certificate_link: "https://www.freelancer.com/u/MianInzamam",
      alt_name: "Freelancer",
      color_code: "#007fed",
    },
    {
      id: "4",
      title: "Kwork",
      subtitle: "Muhammad Inzamam Shakir",
      discription: "Blockchain Developer | DEXs Expert",
      rating: "⭐⭐⭐⭐⭐",
      projects: "Projects In Progress (1)",
      logo_path: "kwork.png",
      certificate_link: "https://kwork.com/user/m_inzamam_shakir",
      alt_name: "Kwork",
      color_code: "#333333",
    },
    {
      id: "5",
      title: "Revolancer",
      subtitle: "Muhammad Inzamam Shakir",
      discription: "Blockchain Developer | DEXs Expert",
      rating: "⭐⭐⭐⭐⭐",
      projects: "Projects In Progress (2)",
      logo_path: "revo.png",
      certificate_link: "https://www.freelancer.com/u/MianInzamam",
      alt_name: "Freelancer",
      color_code: "#0d253a",
    },
    // color_code: "#8C151599",
    // color_code: "#7A7A7A",
    // color_code: "#0C9D5899",
    // color_code: "#C5E2EE",
    // color_code: "#ffc475",
    // color_code: "#g",
    // color_code: "#ffbfae",
    // color_code: "#fffbf3",
    // color_code: "#b190b0",
  ],
};
const ourSpecialities = {
  ourSpeciality: [
    {
      id: "1",
      title: "Expert Developers",
      subtitle: "AINZICS has Team of Expert Developers",
      discription:
        "We have a team of skilled experts who have in depth understanding of the industry's technology stack and latest tools that are required to develop and deploy your Apps.",
      rating: "⭐⭐⭐⭐⭐",
      logo_path: "upwork.png",
      certificate_link:
        "https://www.upwork.com/freelancers/~01a923268a7790228f",
      alt_name: "upwork",
      color_code: "#14a800",
    },
    {
      id: "2",
      title: "Customized Solutions at Best Price",
      subtitle: "Best Solution at Competetive Prices",
      discription:
        "AINZICS provides customized Software Solutions at competitive pricing. We provide you the latest technology, quality development, 24/7 support, and timely delivery.",
      rating: "⭐⭐⭐⭐⭐",
      logo_path: "fiverr.png",
      certificate_link: "https://www.fiverr.com/digidreamers",
      alt_name: "Fiverr",
      color_code: "#1dbf73",
    },
    {
      id: "3",
      title: "Full Cycle Development Process",
      subtitle: "Complete Working Solutions From Documentation to Launching",
      discription:
        "Our full cycle development services include research & strategy, documentation, development, integration, page design, testing & deployment, and marketing services.",
      rating: "⭐⭐⭐⭐⭐",
      logo_path: "freelancer.png",
      certificate_link: "https://www.freelancer.com/u/MianInzamam",
      alt_name: "Freelancer",
      color_code: "#007fed",
    },
    {
      id: "4",
      title: "Responsive, Quality and Support",
      subtitle: "Quality is Our First Priority",
      discription:
        "AINZICS provides fully responsive, attractive, and beautiful User Interface (UI) and our expert developers will make your product with quality, and AINZCIS's Support is always there for our honorable customers.",
      rating: "⭐⭐⭐⭐⭐",
      logo_path: "kwork.png",
      certificate_link: "https://kwork.com/user/m_inzamam_shakir",
      alt_name: "Kwork",
      color_code: "#333333",
    },
    {
      id: "5",
      title: "Lifetime Maintenance",
      subtitle: "We Own our Working Responsibilities for Lifetime.",
      discription:
        "AINZICS provides the Lifetime Mainetenance to our Honorable Customers, because We take the complete resoponsibility for our working for lifetime and always there for your support regarding our Project.",
      rating: "⭐⭐⭐⭐⭐",
      logo_path: "revo.png",
      certificate_link: "https://www.freelancer.com/u/MianInzamam",
      alt_name: "Freelancer",
      color_code: "#0d253a",
    },
  ],
};

const ourProductsLive = {
  ourLiveProducts: [
    {
      id: "1",
      title: "Converter",
      subtitle: "AINZICS providing you SVG To JSX Conveter",
      discription:
        "We have a team of skilled experts who have in depth understanding of the industry's technology stack and latest tools that are required to develop and deploy your Apps.",
      rating: "⭐⭐⭐⭐⭐",
      product_link: "https://ainzics.com/products/converter",
      alt_name: "upwork",
      color_code: "#14a800",
    },
    {
      id: "2",
      title: "Customized Solutions at Best Price",
      subtitle: "Best Solution at Competetive Prices",
      discription:
        "AINZICS provides customized Software Solutions at competitive pricing. We provide you the latest technology, quality development, 24/7 support, and timely delivery.",
      rating: "⭐⭐⭐⭐⭐",
      logo_path: "fiverr.png",
      product_link: "https://www.fiverr.com/digidreamers",
      alt_name: "Fiverr",
      color_code: "#1dbf73",
    },
  ],
};

const frequentQuestions = {
  question: [
    {
      id: "1",
      query: "What hours are you available?",
      answer:
        "AINZICS is available 24/7. In case of any emergency, we announce it on our site and on social accounts.",
    },
    {
      id: "2",
      query: "What technologies does AINZICS jam to?",
      answer:
        "AINZICS  is the top Software Solutions Provider. AINZICS is one of the best Blockchain Development companies in Pakistan that bring your digital needs to reality.",
    },
    {
      id: "3",
      query: "What software development services does AINZICS Providing?",
      answer:
        "As a Software Development company, we provide our clients with offshore IT services that include: Blockchain Development, Web Application Development, Mobile Application Development, Custom Software Development, and E-Commerce Development.",
    },
    {
      id: "4",
      query: "How can someone join AINZICS?",
      answer:
        "To join AINZICS, You just have to submit your information form and you can also contact us on Facebook, Instagram, and Linkedin.",
    },
    {
      id: "5",
      query: "How can I be sure you work on my project as much as you declare?",
      answer:
        "AINZICS team monitor the Developers and control how much time they spend on each task.  AINZICS uses Harvest Software as well as Redmine to track the time on your project. We also provide the facility to access the information to our clients.",
    },
    {
      id: "6",
      query:
        "Do you provide after-sale services for the constant checks to maintain products?",
      answer:
        "Yes, AINZICS provides after-sale services, all services are provided separately to maintain the effectiveness of your valuable products as per the rapidly changing technologies at very cheap prices.",
    },
    {
      id: "7",
      query: "How does AINZICS organize the communication for remote working?",
      answer:
        "AINZICS point of departure is that in long run, most people feel better and more functional in a team than solo. That is the main reason why AINZICS values having an office where people can work together.",
    },
  ],
};

const getInTouchBtn = {
  question: [
    {
      id: "1",
      headingMain: "Join the AINZICS Community!",
      subHeading: "Read all AINZICS news, chat and stay up-to-date in community-driven social media channels",
    },
  ],
};

const teamMembers = {
  team: [
    {
      id: "1",
      title: "M. Inzamam",
      desig: "Founder",
      image_url: "inzamam.png",
      alt_name: "Ainzico Costa",
      link_facebook: "https://www.facebook.com/ainzics",
      link_linkedin: "https://www.linkedin.com/company/ainzics",
      link_twitter: "https://twitter.com/ainzics",
    },
    {
      id: "2",
      title: "Richarlison",
      desig: "Co-Founder & CEO",
      image_url: "richarlison.jpg",
      alt_name: "Richarlison",
      link_facebook: "https://www.facebook.com/ainzics",
      link_linkedin: "https://www.linkedin.com/company/ainzics",
      link_twitter: "https://twitter.com/ainzics",
    },
    {
      id: "3",
      title: "Alison Stager",
      desig: "Designer",
      image_url: "alison_stager.jpg",
      alt_name: "Alison Stager",
      link_facebook: "https://www.facebook.com/ainzics",
      link_linkedin: "https://www.linkedin.com/company/ainzics",
      link_twitter: "https://twitter.com/ainzics",
    },
    {
      id: "4",
      title: "Crist Liam",
      desig: "Lead Developer",
      image_url: "crist_liam.jpg",
      alt_name: "Crist Liam",
      link_facebook: "https://www.facebook.com/ainzics",
      link_linkedin: "https://www.linkedin.com/company/ainzics",
      link_twitter: "https://twitter.com/ainzics",
    },
    {
      id: "5",
      title: "Heggin Costa",
      desig: "MERN Specialist",
      image_url: "heggin_costa.jpg",
      alt_name: "Heggin Costa",
      link_facebook: "https://www.facebook.com/ainzics",
      link_linkedin: "https://www.linkedin.com/company/ainzics",
      link_twitter: "https://twitter.com/ainzics",
    },
    {
      id: "6",
      title: "Javil Anah",
      desig: "UI & UX",
      image_url: "javil_anah.jpg",
      alt_name: "Javil Anah",
      link_facebook: "https://www.facebook.com/ainzics",
      link_linkedin: "https://www.linkedin.com/company/ainzics",
      link_twitter: "https://twitter.com/ainzics",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I've complete track record of my Services and Expertise. I've done working on a lot of Projects. I love organizing workshops to share my knowledge with others.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience (On Site)",
      experiences: [
        {
          title: "Co-Founder and Blockchain Developer",
          company: "Encodix",
          company_url: "https://encodix.org/",
          logo_path: "encodix.png",
          duration: "March 2021 - Present",
          location: "Mediacom Trade City, Faisalabad",
          description: `Managing company's overall operations, Train, manage and provide guidance to all the staff.
          Develops new and maintains existing applications.
          Increased productivity and problem-solving technics by 20%.
          We primarily on MERN and MEAN Stack. Learning experiencing Leadership Skills. 
          `,
          color: "#0071C5",
        },
        {
          title: "Blockchain Developer",
          company: "Netixsol",
          company_url: "http://netixsol.com/",
          logo_path: "netixsol.png",
          duration: "Jan 2021 - March 2021",
          location: "Koh-e-Noor One, Faisalabad",
          description: `Worked as Blockchain Developer and worked closely to the client and managed the things and functionalities on request. Learnt a lot of new things and even worked besides the talented team Players and seniors. That 
          was the amazing experience for me.`,
          color: "#fe0040",
        },
        {
          title: "Internship as Blockchain Developer",
          company: "Netixsol",
          company_url: "http://netixsol.com/",
          logo_path: "netixsol.png",
          duration: "Sep 2020 - Dec 2020",
          location: "Koh-e-Noor One, Faisalabad",
          description: `Worked as an internship at Netixol closely to the most experienced developers and seniors and learnt a lot about the Project management and discpline. 
          `,
          color: "#ffc475",
        },
        {
          title: "Intership as Blockchain Developer",
          company: "BlocTech Solutions",
          company_url: "https://Bloctechsolutions.com",
          logo_path: "bloctech.png",
          duration: "Oct 2019 - Sep 2020",
          location: "Near Jhang Road, Faisalabad",
          description: `Worked for a single year and learnt the basics of Blockchain and mainly worked at the front-end using reactjs. That was the beginning of the proper Development journey.
          `,
          color: "#47A048",
        },
        {
          title: "Software Developer",
          company: "BT Software Development and Technology",
          company_url: "https://www.btsoftwarehouse.com",
          logo_path: "bt.png",
          duration: "Oct 2018 - Oct 2019",
          location: "Koh-e-Noor One, Faisalabad",
          description: `Learnt about the Basics of Computer Science Degree and Major Concepts of Programming world and Development phases with the most talented and smart working senior developers. 
          `,
          color: "#8C151599",
        },
      ],
    },
    {
      title: "Work Experience (Freelancing)",
      experiences: [
        {
          title: "Kwikswap",
          company: "Kwikswap",
          company_url: "https://kwikswap.org/",
          logo_path: "kwikswap.png",
          duration: "Sep 2020 - March 2021",
          location: "Work From own Office",
          description:
            "Blockchain Developer to completely manage Kwikswap.org Project, with Front-End DEX, Solidity, and Web3 Integrations etc. KwikSwap is a completely decentralised protocol built on the the Ethereum Network + additional layer 2 scaling which means Faster and cheaper transactions",
          color: "#4285F4",
        },
        {
          title: "Kishu",
          company: "Kishu Inu",
          company_url: "https://kishu.com/",
          logo_path: "kishu.png",
          duration: "March 2021 - April 2021",
          location: "Work From own Office",
          description:
            "Designed and Developed the Website. Kishu Inu ($KISHU) is a community-focused, decentralized cryptocurrency with instant rewards!.",
          color: "#196acf",
        },
        {
          title: "Safebreast",
          company: "SafeBreast",
          company_url: "https://safebreast.com",
          logo_path: "safebreast.png",
          duration: "March 2021 - April 2021",
          location: "Work From own Office",
          description:
            "Designed and Developed the Website, DEX, WhitePaper, and Token for SafeBreast Project. SafeBreast Inu ($BREAST) is a decentralized cryptocurrency that rewards both charities and holders as it grows, with transparency and community as its founding principles",
          color: "#d10d26",
        },
        {
          title: "EverRise",
          company: "EverRise",
          company_url: "https://everrisecoin.com",
          logo_path: "everrise.png",
          duration: "April 2021 - May 2021",
          location: "Work From own Office",
          description:
            "Designed and Developed the Website for EverRise Project. EverRise token ($RISE or RISE) is a collateralized cryptocurrency that grants investors holding RISE instant rewards on all transactions, and protects them with its unique buyback and burn protocol.",
          color: "#040f26",
        },
        {
          title: "LouvreSwap",
          company: "Louvre",
          company_url: "https://swap.louvrefinance.com/",
          logo_path: "louvre.png",
          duration: "May 2021 - June 2021",
          location: "Work From own Office",
          description:
            "Designed and Developed the DEX (Swap Interface) for Louvrefinance Project. The Deflationary Defi Token Disrupting The NFT Industry",
          color: "#040f26",
        },
        {
          title: "HuliHuli",
          company: "808Blockchain LLC.",
          company_url: "https://hulihuli.finance",
          logo_path: "hulihuli.png",
          duration: "Aug 2021 - Sep 2021",
          location: "Work From own Office",
          description:
            "Designed and Developed the Website for 808Blockchain for HuliHuli.finance Project, $Huli is a community driven decentralized ecosystem that rewards its holders thanks to active users. Simply Hold $Huli tokens and get rewarded in $UNI or $CAKE on every transaction.",
          color: "#D83B01",
        },
        {
          title: "EgonSwap Analytics",
          company: "Eagle Network",
          company_url: "https://info.egonswap.app/",
          logo_path: "egonswap.png",
          duration: "Oct 2021 - Nov 2021",
          location: "Work From own Office",
          description:
            "Developed the Analytics for EgonSwap. Egonswap is Decentralized Exchange on Binance Smart Chain. ",
          color: "#D83B01",
        },
        {
          title: "TheXDao Analytics",
          company: "XDao",
          company_url: "https://info.thexdao.com/",
          logo_path: "thexdao.png",
          duration: "Aug 2021 - Oct 2021",
          location: "Work From own Office",
          description:
            "Developed the Analytics for TheXDao. TheXDao is Decentralized Exchange on Fantom. ",
          color: "#D83B01",
        },
        {
          title: "EgonSwap DEX",
          company: "Eagle Network",
          company_url: "https://dex.egonswap.app/",
          logo_path: "egonswap.png",
          duration: "Oct 2021 - Nov 2021",
          location: "Work From own Office",
          description:
            "Developed the DEX (Swap and Liquidity) for EgonSwap. Egonswap is Decentralized Exchange on Binance Smart Chain. ",
          color: "#5a900f",
        },
        {
          title: "CloudSwap",
          company: "CloudSwap",
          company_url: "https://cloudswap.app/",
          logo_path: "cloud.png",
          duration: "Nov 2021 - Present",
          location: "Work From own Office",
          description:
            "Currently  working and developing the DEX (Swap and Liquidity) for CloudSwap. CloudSwap is Decentralized Exchange on Binance Smart Chain. ",
          color: "#5a900f",
        },
        {
          title: "MetaVerse",
          company: "MVRS Token",
          company_url: "https://mvrstoken.tech/",
          logo_path: "mvrs.png",
          duration: "DEC 2021 - Present",
          location: "Work From own Office",
          description:
            "MVRS Swap is a DEX for Swap MVRS with other Tokens. It's using for MVRS NFTs and Other Transactions.",
          color: "#044bad",
        },
        {
          title: "Infernoswap",
          company: "Inferno",
          company_url: "https://dex.infernoswap.org/",
          logo_path: "inferno.png",
          duration: "Oct 2021 - Nov 2021",
          location: "Work From own Office",
          description:
            "Developed the DEX (Swap and Liquidity) for InfernoSwap. Infernoswap is Decentralized Exchange on Binance Smart Chain. ",
          color: "#5a900f",
        },
        {
          title: "MoorkllSwap",
          company: "Inferno",
          company_url: "https://dex.moorkllswap.org/",
          logo_path: "moorkll.png",
          duration: "Nov 2021 - Present",
          location: "Work From own Office",
          description:
            "Currently Under Development the DEX (Swap and Liquidity) for MoorkllSwap. Moorkllswap is Decentralized Exchange on Binance Smart Chain. ",
          color: "#5a900f",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "Our projects make use of a vast variety of latest technology tools. Our best experience is to create and work on the latest technology",
  avatar_image_path: "projects_image.svg",
};
// Projects Page
const productsHeader = {
  title: "Products",
  description:
    "Our Current and Upcoming Products make use of a vast variety of latest technology tools. Our best experience is to create and work on the latest technology",
  avatar_image_path: "projects_image.svg",
};

// Projects Page
const portfolioHeader = {
  title: "What we have done?",
  description:
    "Portfolios are a great way to demonstrate the competencies. Portfolio showcases the working to potential employers. It presents evidence of our relevant skills and abilities.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Us",
    profile_image_path: "inzii.png",
    description:
      "You can contact us at the places mentioned below. We will try to get back to you as fast as we can. ",
  },
  blogSection: {
    title: "Blogs",
    subtitle: "AINZICS Blogs are Coming Soon",
    link: "https://twitter.com/ainzics",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      id: "0",
      name: "BetoSwap",
      url: "https://github.com/ainzics/toplyaswap-dex",
      description:
        "BetoSwap is Decentralized Exchange on Binance Smart Chain, Fantom Network, and EgonCoin Network.",
      languages: [
        {
          name: "Solidity",
          iconifyClass: "skill-icons:solidity",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "ReactJS",
          iconifyClass: "skill-icons:react-dark",
        },
        {
          name: "Typescript",
          iconifyClass: "skill-icons:typescript",
        },
      ],
    },
    {
      id: "1",
      name: "Beto Network",
      url: "https://github.com/ainzics/kcc-website",
      description:
        "Website Developed in ReactJS. KCC is the Mission To accelerate the flow of value around the world.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
      ],
    },
  ],
};

const portfolioSites = {
  data: [
    {
      id: "0",
      name: "HuliHuli Finance",
      url: "https://hulihuli.finance",
      description:
        "This is Farms and Pools for Toplyaswap Exchange on Binance Smart Chain (BSC) Test-Network",
      imgurl: [
        {
          id: "1",
          url: "hulihuli/hulihuli.png",
        },
        {
          id: "2",
          url: "hulihuli/hulihuli2.png",
        },
        {
          id: "3",
          url: "hulihuli/hulihuli3.png",
        },
        {
          id: "4",
          url: "hulihuli/hulihuli4.png",
        },
        {
          id: "5",
          url: "hulihuli/hulihuli5.png",
        },
        {
          id: "6",
          url: "hulihuli/hulihuli6.png",
        },
      ],
    },
    {
      id: "1",
      name: "Home | EgonSwap",
      url: "https://home.egonswap.io",
      description:
        "Website Developed in ReactJS. KCC is the Mission To accelerate the flow of value around the world.",
      imgurl: [
        {
          id: "1",
          url: "egonhome/1.png",
        },
        {
          id: "2",
          url: "egonhome/2.png",
        },
        {
          id: "3",
          url: "egonhome/3.png",
        },
        {
          id: "4",
          url: "egonhome/4.png",
        },
        {
          id: "5",
          url: "egonhome/5.png",
        },
        {
          id: "6",
          url: "egonhome/6.png",
        },
      ],
    },
    {
      id: "2",
      name: "Kishu Inu",
      url: "https://kishuinu.com",
      description:
        "Website Developed in ReactJS. KCC is the Mission To accelerate the flow of value around the world.",
      imgurl: [
        {
          id: "1",
          url: "kishu/kishu1.png",
        },
        {
          id: "2",
          url: "kishu/2.png",
        },
        {
          id: "3",
          url: "kishu/3.png",
        },
        {
          id: "4",
          url: "kishu/4.png",
        },
        {
          id: "5",
          url: "kishu/5.png",
        },
        {
          id: "6",
          url: "kishu/6.png",
        },
      ],
    },
    {
      id: "3",
      name: "SatoshiSwap",
      url: "https://satoshiswap.net",
      description:
        "Website Developed in ReactJS. KCC is the Mission To accelerate the flow of value around the world.",
      imgurl: [
        {
          id: "1",
          url: "satoshi/1.png",
        },
        {
          id: "2",
          url: "satoshi/2.png",
        },
        {
          id: "3",
          url: "satoshi/3.png",
        },
        {
          id: "4",
          url: "satoshi/4.png",
        },
        {
          id: "5",
          url: "satoshi/5.png",
        },
      ],
    },
  ],
};

const portfolioSitesDexs = {
  data: [
    {
      id: "1",
      name: "EgonSwap ",
      url: "https://egonswap.io",
      imgurl: [
        {
          id: "1",
          url: "dexes/egonswap/farms.png",
        },
        {
          id: "2",
          url: "dexes/egonswap/swap.png",
        },
        {
          id: "3",
          url: "dexes/egonswap/farming.png",
        },
        {
          id: "4",
          url: "dexes/egonswap/info.png",
        },
      ],
    },
    {
      id: "2",
      name: "GolSwap",
      url: "https://gol.finance",
      imgurl: [
        {
          id: "1",
          url: "dexes/golswap/landing.png",
        },
        {
          id: "2",
          url: "dexes/golswap/swap.png",
        },
        {
          id: "3",
          url: "dexes/golswap/farms.png",
        },
        {
          id: "4",
          url: "dexes/golswap/staked.png",
        },
        {
          id: "5",
          url: "dexes/golswap/info.png",
        },
      ],
    },
    {
      id: "3",
      name: "MVRS | Meta",
      url: "https://swap.mvrstoken.space/#/swap",
      imgurl: [
        {
          id: "1",
          url: "dexes/mvrs/swap.png",
        },
        {
          id: "2",
          url: "dexes/mvrs/opensidebar.png",
        },
      ],
    },
    {
      id: "4",
      name: "KoingFu",
      url: "https://koingfu.com/",
      imgurl: [
        {
          id: "1",
          url: "dexes/koingfu/swap.png",
        },
        {
          id: "2",
          url: "dexes/koingfu/pools.png",
        },
      ],
    },
  ],
};

const specialityCard = {
  data: [
    {
      id: "1",
      image_url: "digiskills.webp",
      alt_tag: "",
      heading: "Hello World",
      para: "Hello World",
    },
    {
      id: "2",
      image_url: "digiskills.webp",
      alt_tag: "",
      heading: "Hello World",
      para: "Hello World",
    },
    {
      id: "3",
      image_url: "digiskills.webp",
      alt_tag: "",
      heading: "Hello World",
      para: "Hello World",
    },
    {
      id: "4",
      image_url: "digiskills.webp",
      alt_tag: "",
      heading: "Hello World",
      para: "Hello World",
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  myprofiles,
  ourServices,
  ourSpecialities,
  ourProductsLive,
  frequentQuestions,
  getInTouchBtn,
  teamMembers,
  specialityCard,
  experience,
  projectsHeader,
  productsHeader,
  portfolioHeader,
  contactPageData,
  projects,
  portfolioSites,
  portfolioSitesDexs,
};
