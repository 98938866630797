import React from "react";

export default function BlockchainImg(props) {
  const theme = props.theme;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="974.755"
      height="674.095"
      data-name="Layer 1"
      viewBox="0 0 974.755 674.095"
    >
      <path
        fill="#2f2e41"
        d="M88.097 294.408C70.05 309.173 99.58 438.776 99.58 438.776s6.562 197.686 20.506 206.709 45.115-.82 57.42-2.461 1.64-123.861-16.406-168.976S157 363.31 157 363.31l97.612 18.866s-59.06 50.037-56.599 60.7 65.622 46.756 77.106 48.396 33.63-65.621 53.318-111.557-134.525-75.465-134.525-75.465l-21.327-40.193s-66.442 15.585-84.488 30.35z"
      ></path>
      <path
        fill="#2f2e41"
        d="M137.088 648.888s-5.953 20.834 0 20.834 16.865 5.952 33.73 3.968 11.904-22.817 0-25.794-8.929-26.785-8.929-26.785c-3.968-7.937-22.817 1.984-22.817 1.984zM221.594 483.221s-10.212 19.11-4.394 20.365 15.23 9.375 32.135 10.992 16.449-19.794 5.44-25.213-3.08-28.067-3.08-28.067c-2.205-8.594-22.722-2.872-22.722-2.872z"
      ></path>
      <path
        fill="#ffb9b9"
        d="M99.17 116.82c1.641 3.28-5.741 44.294-5.741 44.294l6.562 11.484s37.732-14.765 31.99-17.226-4.921-32.81-4.921-32.81-29.53-9.024-27.89-5.743z"
      ></path>
      <circle cx="113.373" cy="79.168" r="50.294" fill="#2f2e41"></circle>
      <circle cx="109.904" cy="32.084" r="20.811" fill="#2f2e41"></circle>
      <path
        fill="#2f2e41"
        d="M119.009 41.51a21.044 21.044 0 002.168.113 20.811 20.811 0 100-41.623 21.044 21.044 0 00-2.168.113 20.812 20.812 0 010 41.397z"
      ></path>
      <circle cx="114.756" cy="103.695" r="34.451" fill="#ffb9b9"></circle>
      <path
        fill="#e6e6e6"
        d="M65.13 156.602c-10.664 2.461-28.71 28.71-28.71 28.71l28.71 28.71s20.506 77.105 22.967 80.386c16.121 21.496 88.59-26.249 88.59-26.249s-2.872-23.377-1.231-34.861c1.578-11.045-6.152-33.221-6.152-33.221s27.069-11.484 21.327-22.968-41.014-37.732-55.779-28.71-44.294 0-44.294 0-14.765 5.743-25.429 8.203z"
      ></path>
      <path
        fill="#ffb9b9"
        d="M274.709 321.887s46.755 40.194 58.239 31.99-44.295-36.911-44.295-36.911zM64.72 307.943c6.561 7.382 37.732-11.484 33.735-19.863s-28.814 8.379-28.814 8.379-11.484 4.101-4.922 11.484z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M65.13 196.796l-34.452 63.98s39.373 32.812 44.294 33.632-9.843 22.147-9.843 22.147-44.295-31.17-61.52-46.755 32.81-84.488 32.81-84.488l14.766-9.023zM165.202 194.335l40.194 62.34s61.52 68.903 69.723 72.185 18.046-12.305 18.046-12.305l-73.825-89.41-27.889-50.036-10.663-4.101z"
      ></path>
      <ellipse
        cx="113.373"
        cy="62.762"
        fill="#2f2e41"
        rx="34.686"
        ry="20.811"
      ></ellipse>
      <path
        fill="#e6e6e6"
        d="M210.677 511.79a4.293 4.293 0 00-4.288 4.287v146.245a4.293 4.293 0 004.288 4.288h498.669a4.293 4.293 0 004.288-4.288V516.077a4.293 4.293 0 00-4.288-4.288z"
      ></path>
      <path fill="#fff" d="M217.134 655.865h485.754v-133.33H217.134z"></path>
      <path
        fill={theme.accentColor}
        d="M641.574 628.205a7.103 7.103 0 100 14.205h28.41a7.103 7.103 0 100-14.205h-28.41z"
      ></path>
      <path fill="#e6e6e6" d="M349.122 614.428H677.087V616.297H349.122z"></path>
      <circle cx="280.567" cy="589.2" r="41.369" fill={theme.accentColor}></circle>
      <path
        fill="#3f3d56"
        d="M349.64 603.993L348.604 602.438 382.684 579.717 406.054 590.935 439.669 569.459 506.537 597.983 559.787 571.825 611.495 592.788 676.468 535.241 677.707 536.64 611.867 594.956 559.86 573.871 506.591 600.038 439.843 571.565 406.184 593.071 382.835 581.864 349.64 603.993z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M355.195 629.182a6.073 6.073 0 100 12.147h63.538a6.073 6.073 0 000-12.147z"
      ></path>
      <path
        fill="#fff"
        d="M296.367 570.692h6.464l-13.45 13.311a12.558 12.558 0 01-17.632 0l-13.448-13.31h6.464l10.217 10.111a7.954 7.954 0 0011.167 0zm-31.684 37.015h-6.464l13.532-13.393a12.558 12.558 0 0117.631 0l13.533 13.393h-6.464l-10.302-10.194a7.954 7.954 0 00-11.167 0z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M275.612 43.354a4.293 4.293 0 00-4.288 4.288v146.245a4.293 4.293 0 004.288 4.288h498.669a4.293 4.293 0 004.288-4.288V47.642a4.293 4.293 0 00-4.288-4.288z"
      ></path>
      <path fill="#fff" d="M282.07 187.43h485.753V54.1H282.07z"></path>
      <path
        fill={theme.accentColor}
        d="M706.51 159.77a7.103 7.103 0 100 14.205h28.41a7.103 7.103 0 000-14.205h-28.41z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M414.057 145.993H742.0219999999999V147.862H414.057z"
      ></path>
      <circle cx="345.502" cy="120.764" r="41.369" fill={theme.accentColor}></circle>
      <path
        fill="#3f3d56"
        d="M414.575 135.557L413.539 134.002 447.619 111.282 470.989 122.5 504.604 101.024 505.058 101.217 571.472 129.548 624.722 103.39 676.43 124.353 741.403 66.805 742.642 68.204 676.802 126.52 624.795 105.436 571.527 131.603 504.779 103.13 471.119 124.635 447.77 113.427 414.575 135.557z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M420.13 160.747a6.073 6.073 0 000 12.146h63.538a6.073 6.073 0 100-12.146z"
      ></path>
      <path
        fill="#fff"
        d="M362.147 115.936c.773-5.177-3.168-7.96-8.558-9.817l1.748-7.012-4.27-1.064-1.702 6.828a176.42 176.42 0 00-3.419-.805l1.715-6.873-4.267-1.064-1.75 7.01c-.928-.211-1.84-.42-2.725-.64l.005-.022-5.887-1.47-1.136 4.56s3.168.725 3.1.77a2.27 2.27 0 011.99 2.483l-1.992 7.989a3.538 3.538 0 01.444.142l-.45-.112-2.792 11.192a1.554 1.554 0 01-1.957 1.014c.043.062-3.102-.775-3.102-.775l-2.12 4.888 5.557 1.385c1.033.259 2.046.53 3.042.785l-1.767 7.094 4.264 1.064 1.75-7.018c1.164.316 2.295.608 3.401.883l-1.743 6.985 4.268 1.064 1.767-7.08c7.279 1.378 12.754.822 15.055-5.76 1.857-5.301-.09-8.36-3.92-10.354 2.788-.646 4.89-2.48 5.45-6.27zm-9.754 13.676c-1.32 5.301-10.245 2.437-13.14 1.717l2.344-9.397c2.894.722 12.172 2.152 10.797 7.68zm1.319-13.753c-1.204 4.822-8.633 2.372-11.043 1.772l2.125-8.523c2.41.6 10.172 1.722 8.918 6.751z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M471.799 277.572a4.293 4.293 0 00-4.288 4.288v146.244a4.293 4.293 0 004.288 4.288h498.669a4.293 4.293 0 004.287-4.288V281.86a4.293 4.293 0 00-4.287-4.288z"
      ></path>
      <path fill="#fff" d="M478.256 421.647H964.01v-133.33H478.256z"></path>
      <path
        fill={theme.accentColor}
        d="M902.696 393.987a7.103 7.103 0 100 14.205h28.41a7.103 7.103 0 100-14.205h-28.41z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M610.244 380.21H938.2090000000001V382.079H610.244z"
      ></path>
      <circle cx="541.689" cy="354.982" r="41.369" fill={theme.accentColor}></circle>
      <path
        fill="#3f3d56"
        d="M610.762 369.775L609.725 368.22 643.805 345.5 667.176 356.718 700.79 335.242 701.245 335.435 767.658 363.766 820.909 337.608 872.617 358.57 937.59 301.023 938.829 302.423 872.989 360.738 820.982 339.654 767.713 365.821 700.966 337.348 667.305 358.853 643.957 347.646 610.762 369.775z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M616.317 394.964a6.073 6.073 0 000 12.147h63.538a6.073 6.073 0 100-12.147z"
      ></path>
      <path
        fill="#fff"
        d="M541.684 382.247L558.431 358.662 541.684 368.55 541.684 368.55 524.947 358.662 541.683 382.247 541.683 382.247 541.684 382.247 541.684 382.247 541.684 382.247z"
      ></path>
      <path
        fill="#fff"
        d="M541.683 365.381L541.683 365.381 541.684 365.381 541.684 365.381 558.421 355.488 541.684 327.717 541.684 327.717 541.684 327.717 541.683 327.717 541.683 327.717 524.947 355.488 541.683 365.381z"
      ></path>
    </svg>
  );
}
