import React from "react";
import "./ProjectCard.css";
import { Fade } from "react-reveal";
import { style } from "glamor";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function GithubProj({ repo, theme }) {
  function openRepoinNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  const styles = style({
    color: "rgb(88, 96, 105)",
    backgroundColor: "rgb(255, 255, 255)",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 10px 30px -15px",
    padding: "2rem",
    cursor: "pointer",
    borderRadius: "5px",
    height: "100%",
    transition: "all 0.2s ease-in-out",
    ":hover": {
      boxShadow: `${theme.imageDark} 0 2px 15px`,
    },
  });

  return (
    <div>
      <Fade bottom duration={2000} distance="110px">
        <div
          {...styles}
          key={repo.id}
          onClick={() => openRepoinNewTab(repo.clone_url)}
          style={{ backgroundColor: theme.projectCard }}
        >
          <div className="repo-name-div">
            <p className="repo-name" style={{ color: theme.text }}>
              {repo.name.replace(/^./, str => str.toUpperCase())}
            </p>
          </div>
          <p className="repo-description" style={{ color: theme.text }}>
            {repo.description}
          </p>

          <div className="repo-language-div">
            <OverlayTrigger
              key={repo.language}
              placement={"top"}
              overlay={
                <Tooltip id={`tooltip-top`}>
                  <strong>{repo.language}</strong>
                </Tooltip>
              }
            >
             
            <p className="repo-language" style={{ color: theme.langColor }}>
              {repo.language}
            </p>
            </OverlayTrigger>
          </div>
          {/* <div className="repo-details">
            <ProjectLanguages logos={repo.language} />
          </div> */}
        </div>
      </Fade>
    </div>
  );
}
