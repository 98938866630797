import React, { useState, useRef, useEffect } from "react";
import "./Faqs.css";
import { Fade } from "react-reveal";
import { FiPlus } from "react-icons/fi";

const Faqs = (props) => {
  const theme = props.theme;
  const query = props.que
  const [active, setActive] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : "0px";
  }, [contentRef, active]);

  const toggleAccordion = () => {
    setActive(!active);
  };
  return (
    <Fade right duration={2000}>
      <div className="faqs-app">
        <div>
          <button
            className={`question-section ${active}`}
            onMouseEnter={toggleAccordion}
            onMouseLeave={toggleAccordion}
          >
            <div>
              <div className="question-align">
                <h3 style={{ color: theme.text }}>
                  {query.query}
                </h3>
                <FiPlus
                  className={active ? `question-icon rotate` : `question-icon`}
                />
              </div>
              <div
                ref={contentRef}
                className={active ? `answer answer-divider` : `answer`}
              >
                <p style={{ color: theme.text }}>{query.answer}</p>
              </div>
            </div>
          </button>
        </div>
      </div>
    </Fade>
  );
};

export default Faqs;
