import React from "react";
import "./Footer.css";
import { Fade } from "react-reveal";
import { greeting } from "../../portfolio.js";
import SocialMedia from "../../components/socialMedia/SocialMedia";
/* eslint-disable jsx-a11y/accessible-emoji */

export default function Footer(props) {
  const theme = props.theme
  return (
    <div className="footer-div">
      <Fade>
        <p className="footer-text" style={{ color: props.theme.secondaryText }}>
          Developed by
          {/* <span role="img">❤️</span>  */} {greeting.title2}
        </p>
        <div className="footer-grid-section">
          <div className="footer-reserve-statement">
            <p>Copyright © 2022 All Rights Reserved by AINZICS.</p>
          </div>
          <div className="footer-social-links">
            <SocialMedia />
          </div>
          <div style={{ backgroundColor: "trasparent" }}>
            <a
              style={{
                borderColor: theme.primaryColor,
                color: theme.text,
              }}
              className="footer-div-terms"
              href="https://ainzics.com/#/home"
              target="blank"
            >
              Terms & Conditions
            </a>
            <a
              style={{
                borderColor: props.theme.primaryColor,
                color: props.theme.text,
              }}
              className="footer-div-privacy"
              href="https://ainzics.com/#/home"
              target="blank"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </Fade>
    </div>
  );
}
